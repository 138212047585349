<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
                </a>
            </div>
            <!-- <div class="buttons">
                <button (click)="goBack()">Crear nueva suscripción</button>
                <img src="../../assets/icons/Preferencias-green.png" alt="icono preferencias">
                <app-menu></app-menu>
            </div> -->
        </div>
        <div class="setting-data">
            <div class="second-headboard">
                <h1>Configuración de la cuenta</h1>
                <!-- <img src="../../assets/icons/1234.png" alt="logo de Billcentrix"> -->
                <div class="linked-icons" >
                    <button class="active">Registro inicial</button>
                    <button   (click)="goToTemplates()">Crear plan</button>
                    <button>Activar suscripción</button>
                    <!-- <img src="../../assets/icons/1_cuenta_on.png" alt="Paso 1: Cuenta">
                    <img src="../../assets/icons/2_portales_off.png" alt="Paso 2: Portal" (click)="goToTemplates()">
                    <img src="../../assets/icons/3_personalizar_off.png" alt="Paso 3: Personalizar" (click)="goToFeatures()">
                    <img src="../../assets/icons/4_planes_off.png" alt="Paso 4: Planes" (click)="goToPlans()"> -->
                    </div>
            </div>
            <div class="general-data">
                <h1>Datos generales</h1>
                <form [formGroup]="partnerForm" >
                <div class="grid">
                    <div class="left">
                        <div class="items">
                            <label for="name">Nombre de su empresa*</label>
                            <input [readOnly]="isNewService" [formControl]="partnerForm.controls['name_partner']" 
                                    type="text" placeholder="Nombre de su empresa">
                                    <small
                                    *ngIf="partnerForm.controls['name_partner'].hasError('required') &&
                                     partnerForm.controls['name_partner'].touched"
                                    class="form-error-msg"> Nombre de su empresa es requerido </small>
                        </div>
                        <div class="items">
                            <label for="country">País*</label>
                            <div class="select-wrapper">
                                <select id="country" [formControl]="partnerForm.controls['country']" (change)="cityByCountry($event.target.value)">
                                        <option [disabled]="isNewService" disabled="" selected="" value="">Seleccione una opción</option>
                                        <option [disabled]="isNewService"  *ngFor="let country of countries" [value]="country.id" >{{ country.name_country }}</option>
                                </select>
                                <i class="arrow"></i>
                            </div>
                        </div>

                        <div class="items">
                            <label for="country">Moneda*</label>
                            <div class="select-wrapper">
                                <select id="currency" [formControl]="partnerForm.controls['currency_id']" >
                                    <option  selected="" value="">Seleccione una opción</option>
                                    <option  *ngFor="let currency of currencies" [value]="currency.id" >  {{ currency.codeCurrency }} - {{ currency.nameCurrency }}</option>
                                </select>
                                <i class="arrow"></i>
                            </div>
                        </div>
                        <div class="items">
                            <label for="phone">Teléfono*</label>
                            <input [readOnly]="isNewService" (keypress)="onlyNumberKey($event)" [formControl]="partnerForm.controls['phone_partner']" type="text" placeholder="(000) 000-0000">
                            <small *ngIf="partnerForm.controls['phone_partner'].hasError('required') && partnerForm.controls['phone_partner'].touched"
                                    class="form-error-msg"> Teléfono es requerido 
                            </small>
                        </div>

                        <div class="items space">
                            <label for="country">Recibir correos para nuevas suscripciones?*</label>
                            <button class="info-button" (mouseenter)="showHelpText=true" (mouseleave)="showHelpText=false">
                                ?
                               

                                <span class="tooltip-text" *ngIf="showHelpText" >
                                    <h2>Recibir correos</h2>Esto nos permite enviarte un correo electronico como notificacion cada vez que un cleinte realize una suscripción a cualquiera de tus planes.</span>
                            </button>
                            <div class="select-wrapper">
                                <select [formControl]="partnerForm.controls['create_email_notification']">
                                    <option   selected="true" value="false">NO</option>
                                    <option   value="true" >SI</option>
                                </select>
                                <i class="arrow islarge"></i>
                                <small  *ngIf="partnerForm.controls['create_email_notification'].hasError('required') && partnerForm.controls['create_email_notification'].touched"
                                        class="form-error-msg"> Campo es requerido 
                                </small>
                            </div>
                            
                        </div>

                    </div>
                    <div class="right">
                        <div class="items">
                            <label for="address">Dirección*</label>
                            <input [readOnly]="isNewService" [formControl]="partnerForm.controls['adress_partner']"  type="text" placeholder="Escriba aquí la dirección física de su negocio">
                            <small *ngIf="partnerForm.controls['adress_partner'].hasError('required') && partnerForm.controls['adress_partner'].touched"
                                   class="form-error-msg"> Dirección es requerida </small>
                        </div>
                        <div class="items">
                            <label for="country">Ciudad*</label>
                            <div class="select-wrapper">
                                <select [formControl]="partnerForm.controls['city']">
                                    <option [disabled]="isNewService"  selected="" value="">Seleccione una opción</option>
                                    <option [disabled]="isNewService"  *ngFor="let city of cities" [value]="city.id" >{{ city.name_city }}</option>
                                </select>
                                <i class="arrow"></i>
                                <small  *ngIf="partnerForm.controls['city'].hasError('required') && partnerForm.controls['city'].touched"
                                        class="form-error-msg"> Ciudad es requerido 
                                </small>
                            </div>
                        </div>
                        <div class="items">
                            <div class="elements-separed">
                                <label for="phone">Nombre de tu servicio de suscripción*</label><button class="info-button" (mouseenter)="showHelpText=true" (mouseleave)="showHelpText=false">
                                    ?
                                    <span class="tooltip-text"  >
                                        <h2>Nombre del servicio</h2>Este nombre también será usado para crear el sub-dominio de su portal y se puede editar en
                                        cualquier momento.</span>
                                </button>
                            </div>
                            <input (keypress)="omit_special_char($event)" [readOnly]="exist"  [formControl]="partnerForm.controls['name_portal']" type="text" placeholder="¿Cómo se llama tu negocio?">
                              </div>
                        
                            <small *ngIf="partnerForm.controls['name_portal'].hasError('required') && partnerForm.controls['name_portal'].touched"
                                   class="form-error-msg"> Nombre de portal es requerido </small>

                                   <div class="items space">
                                    <label for="country">Permitir cobrar deudas vencidas a los clientes?*</label>
                                    <button class="info-button" (mouseenter)="showHelpText=true" (mouseleave)="showHelpText=false">
                                        ?
                                       
        
                                        <span class="tooltip-text" *ngIf="showHelpText" >
                                            <h2>Cobrar de deudas</h2>El cobro de deudas debe reservarse para compromisos recurrentes con clientes y no se aconseja para servicios de comercio electrónico. Si decide implementarlo, su servicio aplicará un cargo de acuerdo con la frecuencia seleccionada para sus planes (semanal, mensual, etc.). En caso de que no se pueda cobrar al cliente, el cargo se mantendrá pendiente hasta que este actualice su método de pago, lo que podría resultar en la acumulación de varios meses de servicio si el cliente no cancela su suscripción.</span>
                                    </button>
                                    <div class="select-wrapper">
                                        <select [formControl]="partnerForm.controls['acummulate_debt']">
                                            <option   selected="true" value="false">NO</option>
                                            <option   value="true" >SI</option>
                                        </select>
                                        <i class="arrow islarge"></i>
                                        <small  *ngIf="partnerForm.controls['acummulate_debt'].hasError('required') && partnerForm.controls['acummulate_debt'].touched"
                                                class="form-error-msg"> Campo es requerido 
                                        </small>
                                    </div>
                                    
                                </div>
                    </div>
                </div>
                </form>
            </div>

            <div class="processing-account">
                <h1>Cuenta de procesamiento</h1>
                <form [formGroup]="partnerForm" >
                <div class="grid">
                    <div class="left">
                        <div class="items">
                            <label for="country">Seleccione</label>
                            <div class="select-wrapper">
                                <select id="bank" [formControl]="partnerForm.controls['bank']">
                                    <option disabled="" selected="" value="">Seleccione una opción</option>
                                    <option  *ngFor="let bank of banks" [value]="bank.id" > {{ bank.name }} </option>
                                </select>
                                <i class="arrow"></i>
                                <small *ngIf="partnerForm.controls['bank'].hasError('required') && partnerForm.controls['bank'].touched"
                                        class="form-error-msg"> Banco es requerido 
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="items">
                            <p>Esta será la cuenta bancaria de tu empresa donde se reciben los pagos</p>
                            <div class="flex-style">
                                <label style="color: #5197FF; text-decoration: underline;" (click)="sendContactMail(1)">Contáctanos para integrar o abrir tu cuenta</label>
                                <!-- <button class="custom-account-btn">Cuenta personalizada</button> -->
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
            <div class="notifications">
                <h1>Notificaciones</h1>
                <form [formGroup]="partnerForm" >
                <div class="grid">
                    <div class="left">
                        <div class="items">
                            <div class="elements-separed">
                                <label for="phone">Número de Whatsapp* </label>
                            </div>
                            <div class="flex-style">
                                <label style="color: #5197FF; text-decoration: underline;" for="" (click)="sendContactMail(2)">Contáctanos para personalizar tu número de whatsapp.</label>
                            </div>
                            <div class="select-wrapper">
                                <select id="whatsappNumber" [formControl]="partnerForm.controls['whatsappNumber']" >
                                    <option disabled="" selected="" value="">Seleccione una opción</option>
                                    <option  *ngFor="let number of numbers" [value]="number.id"  >  {{ number.number }} </option>
                                </select>
                                <i class="arrow"></i>
                                <small  *ngIf="partnerForm.controls['whatsappNumber'].hasError('required') && partnerForm.controls['whatsappNumber'].touched"
                                        class="form-error-msg"> WhatsApp es requerido 
                                </small>
                            </div>
                        </div>
                        <p>En caso de requerir habilitar un número de WhatsApp personalizado para notificar a tus clientes, por favor seleccione la opción "Contáctanos para personalizar tu número de whatsapp" y el personal autorizado estará comunicándose con usted.</p>
                    </div>
                    <div class="right">
                        <div class="items">
                            <div class="elements-separed">
                                <label for="phone">Correo electrónico* </label>
                            </div>
                            <div class="flex-style">
                                <label style="color: #5197FF; text-decoration: underline;" for="" (click)="sendContactMail(3)">Contáctanos para personalizar tu email.</label>
                            </div>
                            <input [formControl]="partnerForm.controls['email_partner']" readonly type="text" placeholder="info@billcentrix.com">
                            <small  *ngIf="partnerForm.controls['email_partner'].hasError('required') && partnerForm.controls['email_partner'].touched"
                                     class="form-error-msg"> Email es requerido </small>
                        </div>
                        <p>Las comunicaciones a tus clientes estarán siendo enviadas mediante el correo: <a href="mailto:info@billcentrix.com">info@billcentrix.com</a>. En caso de requerir habilitar un correo personalizado, por favor selecciona <a (click)="sendContactMail(3)" style="color: #5197FF; text-decoration: underline;">contáctanos para personalizar tu email</a> y el personal autorizado estará comunicándose con usted.</p>
                        <small *ngIf="partnerForm.controls['email_partner'].hasError('email') && partnerForm.controls['email_partner'].touched"
                                   class="form-error-msg"> Correo Invalido. El formato debe ser example@dot.com </small>
                    </div>
                </div>
                </form>
            </div>
        </div>
        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
            <button class="continue-btn" (click)="update()" *ngIf="exist" >Guardar y continuar</button>
            <button class="continue-btn" (click)="send()"  *ngIf="!exist">Continuar</button>
        </div>
    </div>
</body>