import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '../../services/notification/toast.service';
import { WebService}  from '../../services/web/web.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {PaymentPopupComponent} from "./payment-popup/payment-popup.component";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent {

  plans : any = [];
  
  form: FormGroup ;

  @ViewChild('inputFile') myInputVariable: ElementRef | undefined;


  frecuencies : any;
  frecuencyName: string = 'Mensuales';
  imageName = null;
	image64: any;
	imageType: any;
  onboardingConfigID: any;
  showPaymentButton : Boolean = false;
  goToMyPortals : Boolean = false;

  constructor(private dialog: MatDialog,private activateRoute : ActivatedRoute, private router : Router,private toast : ToastService,private spinner : SpinnerService,private webService : WebService ) {
    this.plans.push(1);

    this.form = new FormGroup({
     // id: new FormControl(''),
      name_plan: new FormControl('', Validators.required),
      feeCost_plan: new FormControl('', Validators.required),
      freezeCostPlan : new FormControl('', Validators.required),
      enrollmentCost_plan: new FormControl(0),
      frecuency : new FormControl('', Validators.required),
      status_plan : new FormControl(1, Validators.required),
      taxRate_plan : new FormControl('', Validators.required),
      service: new FormControl(''),
      description : new FormControl('', Validators.required),
      idPlan_partner : new FormControl("123", Validators.required),
      cobro_by_partner : new FormControl(false, Validators.required),
      verify_invoices_on_partner : new FormControl(false, Validators.required),
      charge_inmediately : new FormControl(false, Validators.required),
      qty_months_expiration_plan: new FormControl(''),
      is_visible_plan: new FormControl(true, Validators.required)
    });

  }

  ngOnInit(): void {
    var sesion = JSON.parse(localStorage.getItem('sesion')!);

      this.spinner.open();

      this.webService.get(this.webService.HOST + "/frecuency").subscribe( response =>{
        this.frecuencies = response.result;
        this.activateRoute.queryParams.subscribe(queryParams =>{
          if(queryParams['config']){
            this.onboardingConfigID = +queryParams['config'];
            this.habilitatePaymentButton();
            this.getConfigByID();
          }else{
            if(sesion.config !=  null){
              if(sesion.config.length === 1){
                this.onboardingConfigID = +sesion.config[0];
                this.habilitatePaymentButton();
                this.getConfigByID();
              }
            }
          }
        }, err =>{
          this.toast.showError(err);
        });
      }, err =>{
        this.spinner.close();
        this.toast.showError(err);
      });


  }

  habilitatePaymentButton(){
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID ).subscribe( response =>{
      
      if(response.result.service.current_onboarding_step == 5){
        this.showPaymentButton = true;
      }

      if(response.result.service.current_onboarding_step == 5 || response.result.service.current_onboarding_step == 6 ){
        this.goToMyPortals = true;
      }

    }, err =>{
      this.toast.showError(err);
      this.spinner.close();
    });
  }

  getConfigByID(){
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{
      this.spinner.close();
      this.form.controls["service"].setValue({id:config.result.service.id});
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  myportals(){
    this.toast.showInfo("Funcionalidad en desarrollo, debe mostrar pantalla con portales")
  }

  send(){

    this.form.value.frecuency = { id : +this.form.value.frecuency }
    this.form.value.image64 = this.image64;
    this.form.value.imageName = this.imageName;
    this.form.value.imageType = this.imageType;
    this.form.value.onboarding_config = { id :  this.onboardingConfigID }
    this.form.value.qty_months_expiration_plan = +this.form.value.qty_months_expiration_plan;
    this.form.value.is_visible_plan = this.form.value.is_visible_plan === 'true';
    if(!this.form.valid){
      return this.toast.showError("Porfavor, complete el formulario...")
    }

    this.spinner.open();

    this.webService.post({plan:this.form.value, onboarding:true}, this.webService.HOST + "/plan").subscribe(response =>{

      this.spinner.close();
    
      // this.form.reset({}, { emitEvent: false });
      // this.form.controls["idPlan_partner"].setValue("123");
      // this.form.controls["status_plan"].setValue(1);
      // this.form.controls["cobro_by_partner"].setValue(false);
      // this.form.controls["verify_invoices_on_partner"].setValue(false);
      // this.form.controls["charge_inmediately"].setValue(false);
      // @ts-ignore: Object is possibly 'null'.
      // this.myInputVariable.nativeElement.value = '';
      // this.resetFormVariables();
      // this.ngOnInit();
      this.toast.showSuccess("Plan creado correctamente...");
      this.router.navigate(["/edit/portal/"+this.onboardingConfigID+"/my-plans"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"}); 
      
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });


  }

  resetFormVariables() {
    // Resetear las variables del formulario
    this.imageName = null;
    this.image64 = null;
    this.imageType = null;
    // Agrega cualquier otra variable que necesites restablecer aquí

    // Resetear los valores del FormGroup a sus valores originales o valores por defecto
    this.form.reset({
      name_plan: '',
      feeCost_plan: '',
      freezeCostPlan: '',
      enrollmentCost_plan: 0,
      frecuency: '',
      status_plan: 1,
      taxRate_plan: '',
      service: '',
      description: '',
      idPlan_partner: '123',
      cobro_by_partner: false,
      verify_invoices_on_partner: false,
      charge_inmediately: false,
      frecuencyName:'Mensuales'
    });
  }

  onLogoChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
      this.imageName = file.name;
      this.imageType = file.type;
      this.image64 = reader.result!.toString();
      };
    }
  }

  goBack(){
    this.router.navigate(["/edit/portal/" + this.onboardingConfigID]);
  }

  goToPortals(){
    this.router.navigate(["/my-portals"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }

  // PENDIENTE
  processPayment(){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentPopupComponent, {
      width: '100%',
      disableClose: true,
      data: {  configID : this.onboardingConfigID }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(res != undefined &&  res == 1){// SOLO SI ES COBRADO
          this.router.navigateByUrl("/congratulation");
        }
        // else{
        //   window.location.reload();
        // }
      })
  }

  onChangeFrecuency(index: number) {
    index = index - 1;  // restamos 1 al índice
    this.frecuencyName = this.frecuencies[index].name_frecuency;
  }

  handleCreatePlanClick() {
    this.router.navigateByUrl('/plans');
  }
  
  goToTemplates(){
    this.router.navigate(["/portal"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  

  }

 
  goToAccount(){
    this.router.navigate(["/settings"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }

}
