<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
                </a>
            </div>
            <div class="buttons">
                <img src="../../assets/icons/user.png" alt="icono usuario" style="width:50px; height:50px;">
                <h4>{{ username }}</h4>
                <!-- <img src="../../assets/icons/Preferencias-green.png" alt="icono preferencias"> -->
                <app-menu></app-menu>
            </div>
        </div>
        <div class="editing-preferences">
            <form [formGroup]="form" >
            <h1>Editar Provincias y Corregimientos</h1>
            <div class="change-of-password">
                <div class="grid">
                    <div class="left">
                        <div class="items">
                            <mat-form-field style="width: 85%;">
                                <mat-label>Provincias</mat-label>
                                <mat-select (selectionChange)="onProvinceChange(false)" [formControl]="form.controls['provinces']" multiple>
                                <mat-option *ngFor="let province of provincesList" [value]="province" >{{province.name}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                        
                    </div>
                    <div class="right">
                        <div class="items">
                            <mat-form-field style="width: 85%;">
                                <mat-label>Corregimientos</mat-label>
                                <mat-select [formControl]="form.controls['corregimientos']" multiple>
                                <mat-option *ngFor="let corre of corregimientos" [value]="corre" >{{corre.name}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            </form>
        </div>
        <div class="buttons">
            <button (click)="goBack()" >Atrás</button>
            <div class="action-buttons">
                <!-- <button class="edit-btn">Editar</button> -->
                <button (click)="send()">Guardar</button>
            </div>
        </div>
    </div>
</body>
