import { ChangeDetectorRef,Renderer2,Component, Inject, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FacPopupComponent} from '../fac-popup/fac-popup.component';
import { DomSanitizer} from '@angular/platform-browser';
import { config } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { UniqueCodeService } from './unique-code.service';
import { interval, Observable, of, timer,Subject } from 'rxjs';
import { catchError, map, switchMap, delay,takeWhile  } from 'rxjs/operators';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css']
})
export class PaymentPopupComponent implements OnInit {

  private pollingSuccess = new Subject<void>();
  form: any ;
  errorNumber = true;
  numberRequired = false;
  id_process: any;
  iframeElement: HTMLIFrameElement;
  onboardingPlan : any;
  configID: any;
  feeCostPlan: number | null = null;
  uniqueCode: string = "";
  myIp: any;
  myHeight: any;
  myWidth: any;
  jwtTokenForThird: string; // Añadir esta línea
  transactionIdForThird: string; // Añadir esta línea
  transactionId: string;
  iframeValue: string;
  iframeName: string;
  iframeUrl: string;
  redirectUrl: string;
  dataObject : any;
  chp : any;
  retryCount = 0;
  maxRetries = 5;

  constructor(private activateRoute : ActivatedRoute,public dialogRef: MatDialogRef<PaymentPopupComponent>,
    private sanitizer: DomSanitizer,private dialog: MatDialog,private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private uniqueCodeService: UniqueCodeService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
   
    @Inject(DOCUMENT) private document: Document,
    private webService : WebService, private toast : ToastService,
     private spinner : SpinnerService,
     private router : Router,
     @Inject(MAT_DIALOG_DATA) public data: any) {

    this.spinner.open();
    this.form = new FormGroup({
      plan: new FormControl('1', [
        Validators.required
      ]),
      numberCreditCard: new FormControl('', [ Validators.required
      ]),
      nameHolderCreditCard : new FormControl('', Validators.required),
      expMonthCreditCard: new FormControl('', Validators.required),
      expYearCreditCard: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required),
      street: new FormControl(''),
      city: new FormControl(''),
      postalCode: new FormControl(''),
      phone: new FormControl('')
    });

    this.webService.get(this.webService.HOST + "/config/key/" + "billcentrix_onboarding_suscription_plan").subscribe(response =>{
      this.spinner.open();
      this.onboardingPlan = +response.result.value_config;
     
      this.activateRoute.queryParams.subscribe(queryParams =>{

        this.webService.get(this.webService.HOST + "/plan/" + this.onboardingPlan).subscribe(plan=>{
          // NICO ESTE ES EL COSTO DEL PLAN
          this.feeCostPlan = +plan.result.feeCost_plan;
          console.log(plan.result.feeCost_plan);
          if(queryParams['config']){
        
            this.configID = +queryParams['config'];
          
           
          }else{
           
                  this.configID = data.configID;
            
            
          }
          this.spinner.close();
        }, err =>{
          this.spinner.close();
          console.log(err)
        });
       
        

       

      }, err =>{
        this.toast.showError(err);
      });


    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    })

  }

  ngOnInit(): void {
    this.myHeight = window.innerHeight;
    this.myWidth = window.innerWidth;
    this.myIp = '189.127.166.102';
    // PEDIR AYUDA A NICO - ESTO NO QUIERE FUNCIONAR PARA TOMAR LA IP
    // this.http.get('https://api.ipify.org/?format=json').subscribe(response => {
    //   console.log(response);
    //   this.myIp = response['ip'];
    // }, err =>{
    //   console.log(err);
    // });

    this.pollingSuccess.subscribe(() => {
      this.continueToNextStep();
    });
    
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const uniqueCode = this.generateUniqueCode();
      this.uniqueCodeService.setUniqueCode(uniqueCode);
      this.uniqueCode = uniqueCode;
      console.log('Código único generado al cargar la página:', uniqueCode);
      this.addUniqueCodeToHead(uniqueCode); // Añadir el uniqueCode al head
      this.addUniqueCodeToBody(uniqueCode); // Añadir el uniqueCode al body
      this.cdr.detectChanges();
    }, 0);
  }


  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.form.controls["phoneNumber_customer"].setValue(obj);
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  // LO DE ABAJO NO CAMBIA MUCHO
  send(){
    if(!this.form.valid){
      return this.toast.showError("Por favor complete el formulario");
    }

    this.spinner.open();
    // AQUI EMPIEZAN ALGUNOS CAMBIOS . COMO VES SE AGREGAN PARAMETROS a LOS JSONS
    if(!this.data.type){
      const data = {
        creditcard:{
           numberCreditCard : this.form.value.numberCreditCard.trim(),
           nameHolderCreditCard : this.form.value.nameHolderCreditCard,
           expMonthCreditCard : this.form.value.expMonthCreditCard,
           expYearCreditCard : this.form.value.expYearCreditCard,
           street: this.form.value.street,
           city: this.form.value.city,
           postalCode: this.form.value.postalCode,
           phone : this.form.value.phone,
           ccv : this.form.value.ccv
         },
         extraData: { // INICIALIZAMOS EL OBJETO CON LA EXTRA DATA QUE NECESITAMOS
          ip: this.myIp, //MUESTRA LA IP CORRESPONDIENTE AHORITA LA TENGO CABLEADA
          fingerprint: this.uniqueCode, // ESTO ES LO QUE NECESITAMOS PARA CYBERSOURCE
          screenHeight: this.myHeight,
          screenWidth: this.myWidth,
          email : localStorage.getItem('username')
        },
         plan : this.onboardingPlan,
         config : this.configID,
         param : 7,
         email :localStorage.getItem('username')
       
       }
       this.sendData(data,"/suscription",true);
    }else{
      const data = {
        creditcard:{
           numberCreditCard : this.form.value.numberCreditCard.trim(),
           nameHolderCreditCard : this.form.value.nameHolderCreditCard,
           expMonthCreditCard : this.form.value.expMonthCreditCard,
           expYearCreditCard : this.form.value.expYearCreditCard,
           ccv : this.form.value.ccv
         },
         extraData: { // INICIALIZAMOS EL OBJETO CON LA EXTRA DATA QUE NECESITAMOS
          ip: this.myIp,
          fingerprint: this.uniqueCode,
          screenHeight: this.myHeight,
          screenWidth: this.myWidth,
          email : localStorage.getItem('username'),
          street: this.form.value.street,
          city: this.form.value.city,
          postalCode: this.form.value.postalCode,
          phone : this.form.value.phone,
        },
         plan : this.onboardingPlan,
         config : this.configID,
         id:this.configID,
         param : 7,
         email :localStorage.getItem('username'),
         type : this.data.type
       }
       this.sendData(data,"/onboarding/manageStatus",true);
    }

  }

  sendData(data : any, url : string, habilitate : boolean){
  // console.log("DATA QUE SE DEBE IR AL PAYER AUTH / O 3DS: ", data)
  console.log("SOLICITAMOS EL 3DS a esta URL : " + this.webService.HOST + url)
  // AQUI ES DONDE VIENE LO BUENO
  // REALIZAMOS EL PROCESO DE 3DS
  this.webService.post(data,this.webService.HOST + url).subscribe(response =>{
    console.log("RESPONSE DEL PROCESO DE 3DS: ", response)
    this.chp = response.id;
    this.id_process = this.chp;
        // AQUI VAS A ENCONTRAR UNA SERIE DE CASOS DONDE powerTranz ES EL CASO DE USO DE SIEMPRE - ESTO NO CAMBIA PARA NADA
        // REPITO NO CAMBIA
        // ESTE PARAMETRO DE bank TE TIENE QUE RETORNAR SIEMPRE SINO NO SABREMOS QUE HACER
        // EN CASO DE QUE NO VENGA HAZMELO SABER 
        if(response.bank == 'powerTranz'){ // ESTE PARAMETRO NUEVO ES CLAVE PARA SABER COMO PROCEDER 
          // POWERTRANZ ES EL BANCO DE SIEMPRE ASI QUE ESO SE DEJA TAL CUAL ESTA - NO SUFRE CAMBIOS
          // this.toast.showInfo("ESTE BANCO ES POWERTRANZ")
            if(response.htmlFormData){
              
              this.id_process = response.id;
              let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
                width: '50%',
                disableClose: true,
                data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), id: this.id_process }
              })
              dialogRef.afterClosed()
                .subscribe(res => {
                  this.myLoadEvent(habilitate);
                })
  
            }else{
            // EN ESTE CASO EL CHALLENGE NO FUE REQUERIDO, POR LO TANTO OBTENEMOS LA SUSCRIPCION EN EL SERVICIO
              if(response.result.status_customerPlan == 1 ){
        
                this.toast.showSuccess("Cobro procesado correctamente, pronto su portal estara en linea");
                if(habilitate){
                  this.activatePortal();
                }else{
                  this.spinner.close();
                  this.dialogRef.close(1);
                  this.router.navigate(["/my-portals"]); 
                }
      
              }else{
      
                this.spinner.close();
                this.dialogRef.close(2);
                this.toast.showError("Su pago fue rechazado por el banco");
              
              }
  
            }
        }else if(response.bank == 'cyberSource'){   // AQUI VIENE TODO EL FLUJO DE CYBERSOURCE
          // APARTIR DE AQUI SE DEBE CREAR UNA IMPLEMENTACION PARA CADA BANCO
          // ESTA ES LA IMPLEMENTACION PARA CYBERSOURCE
          this.toast.showInfo("ESTE BANCO ES CYBERSOURCE");
          if (response.sopResponse.responseCodeDescription != "COMPLETED") { // ESTO ESTRA PROBADO
            this.spinner.close();
            console.log("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
            this.toast.showError("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
            return;
          }
          const transactionIdentifier = response.sopResponse.transactionId;
          const jwtToken = response.sopResponse.auth3DSDetail.token;
          this.uniqueCodeService.setTransactionIdentifier(transactionIdentifier);
          this.createAndSubmitIframeForm(jwtToken, "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect", "ddc-iframe", "ddc-form");
          setTimeout(() => 
          {       
                    // AQUI SIGUE EL PROCESO DE ENROLLMENT - ESTO ESTA FUNCIONANDO CORRECTAMENTE 
                    // AQUI CREE UN SERVICIO QUE TOMA EL REQUEST Y TAMBIEN TOMA UN PARAMETRO call QUE ME DICE QUE TENGO QUE HACER
                    // EN ESTE CASO ME TOCA HACER UN enrollment
                   const updatedData = this.transactionenrollment();
                   let json = { chp : response.id , sop : updatedData , call : "enrollment"};
                   console.log("ESTE ES EL OBJETO QUE VA PARA EL ENROLLMENT", json);
                   this.webService.post(json, this.webService.HOST + "/sop").subscribe(enrollment =>{
                    console.log("RESPUESTA DEL ENROLLMENT: ", enrollment);
                    
                    if (enrollment.result.responseCodeDescription == "AUTHENTICATION_SUCCESSFUL") { // CASO FRICTION LESS PROBADO CORRECTAMENTE
                      //AQUI ES FRITCTION LESSS SE DEBE LANZAR EL COBRO DIRECTAMENTE
                      console.log("ESTE COBRO ES FRICTION LESS")
                      this.handleEnrollmentSuccess(enrollment.result);
                    } else if (enrollment.result.responseCodeDescription == "AUTHENTICATION_FAILED") { // CASO PROBADO - NO SE HACE NADA PORQUE EL ENROLLMENT FALLO
                      console.log("Unsuccessful Frictionless Authentication");
                      console.log("AUTHENTICATION_FAILED NO SE HACE NADA ESTO CUBRE EL TEST 2.2");
                      this.spinner.close();
                      return this.toast.showError("AUTHENTICATION_FAILED");
                    } else if (enrollment.result.responseCodeDescription == "PENDING_AUTHENTICATION") {
                      // ESTO ES LO QUE NECESITO QUE REVISES POR FAVOR 
                      // AQUI RETORNA EL STATUS CORRESPONDIENTE POR LO CUAL PROCEDE A REALIZAR LO QUE DEBE PERO NO MUESTRA EL IFRAME CON EL 3DS POR LO CUAL NO PUEDP AVANZAR
                      this.handlePendingAuthentication(enrollment.result);
                    } else {
                      console.log("REVISAR O CONSULTAR YA QUE ENROLLMENT NO RETORNO NINGUNO DE LOS ESTATUS PREDETERMINADOS");
                      this.spinner.close();
                      return this.toast.showError("REVISAR O CONSULTAR YA QUE ENROLLMENT NO RETORNO NINGUNO DE LOS ESTATUS PREDETERMINADOS");
                    }


                   }, err =>{
                    console.error("ERROR LLAMANDO A ENRONLLMENT: ", err);
                   });
          },
          8000);
          // timer(5000).pipe(
          //   switchMap(() => {
         
          //     console.log('REQUEST PARA ENROLLMENT :', updatedData);
          //     return this.call.post(updatedData, this.BACKENDURL + "EnrollmentAuth");
          //   })
        }else if(response.bank == 'emetec'){ // AQUI VA EL FLUJO PARA EL 2DO FORMULARIO QUE SE HIZO - EN ESTE CASO PARA EMETEC
          // ESTA ES LA IMPLEMENTACION PARA EMETEC
          this.toast.showInfo("ESTE BANCO ES EMETEC");
          this.dataObject = data;
          this.chp = response.id;
          if(response.sopResponse.responseCodeDescription == "transaction pending"){ // CASOS PARA 3DS - SIN PODER PROBAR PORQUE LAS TARJETAS NO ME ARROJAN EL ESTATUS CORRESPONDIENTE 
            // PERO ESTO DBE SEGUIR EL FLUJO TAL CUAL 
            // Almacenar transactionId, iframeValue, iframeName, iframeUrl y redirectUrl de la respuesta
            this.transactionId = response.sopResponse.transactionId;
            this.iframeValue = response.sopResponse.auth3DSDetail.iframeValue;
            this.iframeName = response.sopResponse.auth3DSDetail.iframeName;
            this.iframeUrl = response.sopResponse.auth3DSDetail.iframeUrl;
            this.redirectUrl = response.sopResponse.auth3DSDetail.redirectUrl;
            console.log('Datos almacenados:');
            console.log('transactionId:', this.transactionId);
            // Enviar solicitud POST usando fetch con no-cors para el primer iframe
            this.sendIframeRequest(this.iframeUrl, this.iframeValue).then(() => {
              console.log('Solicitud del primer iframe enviada con éxito');
              this.spinner.close(); // Detener el loader antes de abrir el popup
              
              // Abrir el segundo iframe en un popup
              this.openInteractiveIframePopup(this.redirectUrl, this.iframeValue, response.sopResponse.transactionId);
            }).catch(err => {
              console.error('Error al enviar la solicitud del primer iframe:', err);
              this.spinner.close();
            });
          }else{ // ESCENARIO PROBADO Y FUNCIONANDO CORRECTAMENTE
            // ESTE ESCENARIO RESPONDE SEGUN LO ESPERADO 
            this.spinner.close();
            console.log("NO RETORNO ESTATUS TRANSACTION PENDING PARA 3DS")
            console.log("USANDO EST DATA HACEMOS UN PAYMENT: ", data );
            const updatedData = this.transactionEmetec()
            updatedData.transactionIdentifier = response.sopResponse.transactionId;
            let json = { chp : response.id , sop : updatedData , call : "payment"};
            this.getEmetecPayment(json);
          }


        }else{
          this.toast.showError("ESTE BANCO NO ESTA REGISTRADO")
        }
        
  
      }, err =>{
        console.log(err);
        this.spinner.close();
        this.toast.showError(err);
      });
  }

  myLoadEvent(habilitate : boolean){
    console.log("LOAD EVENT");
    
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/hasplan/" + this.id_process).subscribe(chp =>{
      console.log("LOAD EVENT RESPONSE: ", chp);
      if(chp.result.status_customerPlan  != 4){
        if(chp.result.status_customerPlan == 1 ){

          this.toast.showSuccess("Cobro procesado correctamente, pronto su portal estara en linea");
          if(habilitate){
            this.activatePortal();
          }else{
            this.spinner.close();
            this.dialogRef.close(1);
            this.router.navigate(["/my-portals"]); 
          }
       
        }else{
          
          this.spinner.close();
          this.dialogRef.close(2);
          this.toast.showError("Su pago fue rechazado por el banco");

        }

      }
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });

  }

  activatePortal(){
    this.spinner.open();
    this.webService.post({id: this.configID,plan : this.onboardingPlan}, this.webService.HOST + "/onboarding/habilitate").subscribe( response =>{
      this.spinner.close();
      this.dialogRef.close(1);
      this.toast.showInfo("Felicidades, su dominio ha sido registrado. En breve estara disponible...");
    }, err =>{
      this.spinner.close();
      this.dialogRef.close(1);
      this.toast.showError(err);
    });
  }

  // TODO ESTO ES NUEVO 

  addUniqueCodeToHead(uniqueCode: string) {
    const comment = this.renderer.createComment('script head nico');
    this.renderer.appendChild(this.document.head, comment);
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setAttribute(script, 'src', `https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=bg_avocadoprime${uniqueCode}`);
    this.renderer.appendChild(this.document.head, script);
    // Check if the script loaded correctly
    script.onload = () => {
      console.log('Script loaded successfully.');
    };

    script.onerror = () => {
      console.error('Failed to load the script.');
    };
  }

  addUniqueCodeToBody(uniqueCode: string) {
    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setStyle(iframe, 'width', '100px');
    this.renderer.setStyle(iframe, 'height', '100px');
    this.renderer.setStyle(iframe, 'border', '0');
    this.renderer.setStyle(iframe, 'position', 'absolute');
    this.renderer.setStyle(iframe, 'top', '-5000px');
    this.renderer.setAttribute(iframe, 'src', `https://h.online-metrix.net/fp/tags?org_id=1snn5n9w&session_id=bg_avocadoprime${uniqueCode}`);
    this.renderer.appendChild(noscript, iframe);
    this.renderer.appendChild(this.document.body, noscript);
  }

  generateUniqueCode(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  createAndSubmitIframeForm(jwtToken: string, actionUrl: string, iframeName: string, formId: string): void {
    console.log('Iniciando la creación del iframe');
  
    const iframe = document.createElement('iframe');
    iframe.name = iframeName;
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    console.log('Iframe creado:', iframe);
  
    const form = document.createElement('form');
    form.id = formId;
    form.target = iframeName;
    form.method = 'POST';
    form.action = actionUrl;
  
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
  
    document.body.appendChild(form);
    console.log('Formulario creado y agregado al DOM:', form);
  
    form.submit();
    console.log('Formulario enviado');
  
    iframe.onload = () => {
      iframe.style.display = 'block';
      console.log('Iframe loaded and displayed.');
    };
  
    window.addEventListener("message", (event) => {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        console.log('Mensaje recibido desde:', event.origin);
        let data = JSON.parse(event.data);
        console.log('Mensaje recibido:', data);
      }
    }, false);
  }
  

  private removeIframe() {
    if (this.iframeElement) {
      this.spinner.close();
      document.body.removeChild(this.iframeElement);
      // this.iframeElement = null;
    }
  }


  transactionEmetec(){
    return {
      creditCard:{
        cardholderName : this.form.value.nameHolderCreditCard,
        creditCardDate : this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv : this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: { // INICIALIZAMOS EL OBJETO CON LA EXTRA DATA QUE NECESITAMOS
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email : localStorage.getItem('username'),
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone : this.form.value.phone,
      }
    };
  }

  transactionenrollment(additionalData: any = {}) {
    return {
      creditCard:{
        cardholderName : this.form.value.nameHolderCreditCard,
        creditCardDate : this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv : this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email : localStorage.getItem('username'),
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  private handleEnrollmentSuccess(secondResponse: any) {
    // ESTO QUEDA TAL CUAL SE ESTABA MANEJANDO EN EL FORMULARIO QUE SE HIZO PARA CYBERSOURCE, TOMO LOS DATOS QUE NECESITO Y PROCEDO A REALIZAR UN SALE
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "Y") {
      console.log("Successful Frictionless Authentication");
      console.log("veresEnrolled y paresStatus son = 'Y' ESTO CUBRE EL TEST PARA 2.1 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "A") {
      console.log("Attempts Processing Frictionless Authentication");
      console.log("TEST 2.3 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "U") {
      console.log("Unavailable Frictionless Authentication");
      console.log("TEST 2.4 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "R") {
      console.log("Rejected Frictionless Authentication");
      console.log("TEST 2.5 DE LA DOCUMENTACION");
    } else if (secondResponse.extraData.veresEnrolled == "U") {
      console.log("Authentication not Available on Lookup");
      console.log("TEST 2.6 DE LA DOCUMENTACION");
      console.log("O tambien")
      console.log("Authentication not Available on Lookup");
      console.log("TEST 2.7 DE LA DOCUMENTACION")
      console.log("O tambien")
      console.log("Time-Out");
      console.log("TEST 2.8 DE LA DOCUMENTACION")
      console.log("YA QUE TODOS TIENEN EL MISMO PARAMETRO veresEnrolled == 'U'")
    } else if (secondResponse.extraData.veresEnrolled == "B") {
      console.log("Bypassed Authentication");
      console.log("TEST 2.9 DE LA DOCUMENTACION");
    } else {
      console.log("ENROLLMENT NO RETORNO NINGUNO DE LOS veresEnrolled y paresStatus PREDETERMINADOS");
    }
    // TODO ESTO QUEDA TAL CUAL NO CAMBIA
    // REPITO : NO CAMBIA . TODOS ESTOS PARAMETROS SON ESENCIALES PARA EL PROCESO DE TOMA DE DECISIONES EN EL SALE PARA CYBERSOURCE
    const fourthData = this.createTransactionData(secondResponse.transactionId, {
      auth: {
        authenticationTransactionId: secondResponse.transactionId
      },
      eci: secondResponse.extraData.eci,
      eciRaw: secondResponse.extraData.eciRaw,
      cavv: secondResponse.extraData.cavv,
      paresStatus: secondResponse.extraData.paresStatus,
      veresEnrolled: secondResponse.extraData.veresEnrolled,
      xid: secondResponse.extraData.xid,
      authenticationTransactionId : secondResponse.extraData.authenticationTransactionId,
      acsTransactionId : secondResponse.extraData.acsTransactionId,
      ecommerceIndicator : secondResponse.extraData.ecommerceIndicator,
      specificationVersion : secondResponse.extraData.specificationVersion,
      directoryServerTransactionId : secondResponse.extraData.directoryServerTransactionId,
      ucafAuthenticationData : secondResponse.extraData.ucafAuthenticationData,
      ucafCollectionIndicator : secondResponse.extraData.ucafCollectionIndicator
    });
    let json = { chp : this.chp , sop : fourthData , call : "sale"};
    console.log('REQUEST DIRECTO PORQUE ES FRICTION LESS', json);
    this.webService.post(json, this.webService.HOST + "/sop").subscribe(fourthResponse => {
      console.log('respuesta del SALE :', fourthResponse);
      // LUEGO DEL ENROLLMENTE PROCEDO A VALIDAR LA SUSCRIPCION Y A MOSTRAR EL MENSAJE CORRESPONDIENTE
      this.myLoadEvent(true);
      this.spinner.close();
    });
  }

  private handlePendingAuthentication(secondResponse: any) {
    console.log('SE REALIZA PROCESO DE 3DS YA QUE ENROLLMENT RETORNO PENDING_AUTHENTICATION');
    this.startPolling();
  
    this.jwtTokenForThird = secondResponse.auth3DSDetail.htmlCode;
    this.transactionIdForThird = secondResponse.transactionId;
    console.log('htmlCode para el 3DS:', this.jwtTokenForThird);
  
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "C") {
      console.log("Successful Step-Up Authentication");
      console.log("TEST 2.10 DE LA DOCUMENTACION");
    }
  
    this.createAndSubmitStepUpForm(this.jwtTokenForThird);
  }

  createAndSubmitStepUpForm(jwtToken: string) {
    this.spinner.close();
    console.log('Iniciando StepUp para 3DS');
  
    const iframe = document.createElement('iframe');
    iframe.name = 'step-up-iframe';
    iframe.height = '900';
    iframe.width = '900';
  iframe.style.display = 'block';
  iframe.style.zIndex = '9999';
  iframe.style.top = '0';
  iframe.style.position = 'fixed';
  iframe.style.background = 'white';
    
    document.body.appendChild(iframe);
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    console.log('Iframe para StepUp creado:', iframe);
  
    const form = document.createElement('form');
    form.id = 'step-up-form';
    form.target = 'step-up-iframe';
    form.method = 'post';
    form.action = 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp';
  
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
  
    const inputMD = document.createElement('input');
    inputMD.type = 'hidden';
    inputMD.name = 'MD';
    inputMD.value = 'optionally_include_custom_data_that_will_be_returned_as_is';
    form.appendChild(inputMD);
  
    document.body.appendChild(form);
    console.log('Formulario de StepUp creado y agregado al DOM:', form);
  
    form.submit();
    console.log('Formulario de StepUp enviado');
  
    iframe.onload = () => {
      console.log('Iframe StepUp loaded and displayed.');
    };
  
    window.addEventListener("message", (event) => {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        console.log('Mensaje StepUp recibido desde:', event.origin);
        let data = JSON.parse(event.data);
        console.log('Mensaje StepUp recibido:', data);
      }
    }, false);
  }
  

  createTransactionData(transactionIdentifier: string = '', additionalData: any = {}) {
    return {
      creditCard:{
        cardholderName : this.form.value.nameHolderCreditCard,
        creditCardDate : this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv : this.form.value.ccv
      },
      transactionIdentifier: transactionIdentifier,
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email : localStorage.getItem('username'),
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

async sendIframeRequest(url: string, iframeValue: string): Promise<void> {
    // Crear el cuerpo de la solicitud en formato URL-encoded
    const body = new URLSearchParams();
    body.append('threeDSMethodData', iframeValue);

    console.log('Datos que se envían al iframe:');
    console.log('URL:', url);
    console.log('Datos del formulario:', body.toString());

    // Enviar la solicitud POST al iframeUrl
    try {
        let response: Response;
        let attempts = 0;
        const maxAttempts = 5;
        const delay = 1000; // 0.5 segundos

        do {
            response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
                mode: 'no-cors', // Añadir modo no-cors
                credentials: 'include'
            });

            if (response.status === 200) {
                console.log('Solicitud iframe enviada con éxito.');
                break;
            } else {
                console.error(`Intento ${attempts + 1}: Error en la solicitud iframe:`, response.status, response.statusText);
            }

            attempts++;
            if (attempts < maxAttempts) {
                await new Promise(resolve => setTimeout(resolve, delay)); // Esperar 0.5 segundos antes del siguiente intento
            }
        } while (attempts < maxAttempts);

        if (attempts === maxAttempts && response.status !== 200) {
            console.error('Se alcanzó el número máximo de intentos y la solicitud no fue exitosa.');
        }
    } catch (error) {
        console.error('Error al enviar la solicitud del primer iframe:', error);
    }
}






 openInteractiveIframePopup(url: string, iframeValue: string, transactioID : string): void {
  const dialogRef = this.dialog.open(InteractiveIframeDialog, {
    width: '600px',
    data: { url, iframeValue }
  });

  // Aquí puedes continuar con el siguiente paso después de que el usuario cierre el popup
  this.dataObject.transactionIdentifier = transactioID;

  // ESTO DEBERIA PODER HABILITARSE PARA CERRAR AUTOMATICAMENTE EL POPUP
  // SI ESTO SE PUEDE HABILITAR ENTONCES PODRIAMOS ELIMINAR EL BOTON DE CERRAR EL POPUP
  //   const intervalId = setInterval(() => {
  //   this.getEmetecPayment();
  // }, 3000);

  dialogRef.afterClosed().subscribe(result => {
    console.log('El popup del iframe interactivo se ha cerrado');
    let json = { chp: this.chp, sop: this.dataObject, call: "payment" };
    this.getEmetecPayment(json);
  });
}

getEmetecPayment(json : any){
    
    console.log("USANDO ESTA DATA HACEMOS EL Payment: ", json );
    if (this.retryCount < this.maxRetries) {
      this.retryCount++;
      timer(3000).pipe(
          switchMap(() => this.webService.post(json, this.webService.HOST + "/sop")),
          takeWhile(() => this.retryCount <= this.maxRetries)
      ).subscribe(response => {
          console.log("RETRY RESPONSE PAYMENT: ", response);
          if (response.result.responseCodeDescription == "Transaction succeeded") {
              this.myLoadEvent(true);
          } else if (response.result.responseCodeDescription == "transaction pending") {
              // Continue retrying
              this.getEmetecPayment(json);
          } else {
              this.toast.showError("Hubo un error en el proceso: " + response.result.responseCodeDescription);
          }
      }, err => {
          console.error("ERROR: ", err);
      });
  } else {
      this.toast.showError("Se alcanzó el número máximo de intentos. Transaccion sigue en estatus pending. contacte con el administrador");
  }
}

  startPolling() {
    this.spinner.open();
    const url = this.webService.HOST + "/hasplan/" + this.id_process;
    let polling = true;
  
    interval(3000).pipe(
      takeWhile(() => polling), // Mantener el polling mientras la condición sea verdadera
      switchMap(() => this.http.get(url, { observe: 'response' }).pipe(
        catchError(error => {
          if (error.status === 404) {
            this.spinner.close();
            console.log('Respuesta 404: No encontrado');
            return of(null);
          } else {
            this.spinner.close();
            console.error('Error en la solicitud:', error);
            return of(null);
          }
        })
      ))
    ).subscribe(response => {

      console.log("RESPUESTA",response)
      // NICO ESTA ES LA RESPUESTA QUE VARIA .. AQUI VAS A ESTAR BUSCANDO LO QUE DEJO ABAJO
      // MIENTRAS EL status_customerPlan SEA == 100 QUIERE DECIR QUE EL BANCO NO HA RESPONDIDO 
      // POR LO TANTO TIENES QUE SEGUIR INTENTANDO EL REQUEST HASTA QUE ESTO TE CAMBIE
      // ESTO NO ESTA TOTALMENTE PROBADO PORQUE NO HE PODIDO EJECUTAR BIEN EL 3DS NO ME MUESTRA EL IFRAME

      // Acceder al cuerpo de la respuesta utilizando response.body
      if (response && response.body) { // Verifica si 'response' y 'response.body' no son nulos
        console.log("RESPUESTA", response);
  
        const responseBody: any = response.body;
  
        if (responseBody.result && responseBody.result.status_customerPlan == 100) {
          console.log('Respuesta 200: OK');
          console.log('Datos:', responseBody);
          this.spinner.open();
          polling = false; // Detener el polling
          this.pollingSuccess.next(); // Emitir evento de éxito
          this.removeIframe(); // Eliminar el iframe
        }
      }

    });
  }


  // SE SUPONE QUE ESTA ES LA FUNCION QUE SE VA A EJECUTAR LUEGO DEL 3DS 
  // HACE EL VALIDATE Y EL SALE EN CASO DE QUE CORRESPONDA
  continueToNextStep() {
    this.spinner.open();
  
    const transactionIdentifier = this.uniqueCodeService.getTransactionIdentifier();
  
    if (!this.jwtTokenForThird || !this.transactionIdForThird) {
      console.error('No se encontró jwtTokenForThird o transactionIdForThird para la tercera petición.');
      this.spinner.close();
      return;
    }
  
    const thirdData = this.createTransactionData(transactionIdentifier, {
      auth: {
        authenticationTransactionId: this.transactionIdForThird,
        signedPares: this.jwtTokenForThird
      }
    });
  
    let json = { chp: this.chp, sop: thirdData, call: "validate" };
    this.spinner.open();
    console.log('REQUEST PARA VALIDATE AUTH', json);
    this.webService.post(json, this.webService.HOST + "/sop")
      .pipe(
        switchMap(thirdResponse => {
          console.log('Respuesta para VALIDATE AUTH:', thirdResponse);
  
          if (thirdResponse && thirdResponse.result && thirdResponse.result.responseCodeDescription === "AUTHENTICATION_SUCCESSFUL") {
            console.log("Successful Step-Up Authentication ON VALIDATE AUTH");
  
            const extraData = thirdResponse.result.extraData;
  
            const fourthData = this.createTransactionData(transactionIdentifier, {
              auth: {
                authenticationTransactionId: this.transactionIdForThird
              },
              eci: extraData.eci,
              eciRaw: extraData.eciRaw,
              cavv: extraData.cavv,
              authenticationTransactionId: extraData.authenticationTransactionId,
              acsTransactionId: extraData.acsTransactionId,
              paresStatus: extraData.paresStatus,
              veresEnrolled: extraData.veresEnrolled,
              xid: extraData.xid,
              ecommerceIndicator: extraData.ecommerceIndicator,
              specificationVersion: extraData.specificationVersion,
              directoryServerTransactionId: extraData.directoryServerTransactionId,
              ucafAuthenticationData: extraData.ucafAuthenticationData,  
              ucafCollectionIndicator: extraData.ucafCollectionIndicator
            });
  
            let saleJson = { chp: this.chp, sop: fourthData, call: "sale" };
            console.log('REQUEST PARA SALE', saleJson);
  
            return this.webService.post(saleJson, this.webService.HOST + "/sop");
          } else {
            console.log("VALIDATE AUTH RETORNO AUTHENTICATION_FAILED");
            this.spinner.close()
            return of(null);
          }
  
        }),
        catchError(error => {
          console.error('Error en la cadena de peticiones:', error);
          this.toast.showError(`Error en VALIDATE AUTH: ${error.message}`);
          this.spinner.close();
          return of(null);
        })
      )
      .subscribe(fourthResponse => {
        this.spinner.open()
        if (fourthResponse && fourthResponse.result) {
          this.spinner.close();
          console.log('Respuesta del SALE:', fourthResponse);
          if (fourthResponse.result.responseCodeDescription === 'AUTHORIZED') {  // Aquí puedes ajustar según la respuesta que recibas
            this.toast.showSuccess("Pago procesado correctamente, su portal estará en línea pronto.");
            this.dialogRef.close(1);
            this.router.navigate(["/my-portals"]);
          } else {
            this.toast.showError("El pago no se procesó correctamente. Por favor, intente nuevamente.");
          }
        } else {
          this.spinner.close();
          console.log('SALE no fue exitoso o no se ejecutó debido a un error previo.');
          this.toast.showError("No se obtuvo una respuesta del proceso de pago. Verifique la transacción o intente nuevamente.");
        }
        this.spinner.close();
      });
      
  }
  
  

}

@Component({
  selector: 'interactive-iframe-dialog',
  template: `
    <h1 mat-dialog-title>Complete la verificación</h1>
    <div mat-dialog-content>
      <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(data.url)" width="100%" height="400px" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"></iframe>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onClose()">Cerrar</button>
    </div>
  `,
  styles: []
})
export class InteractiveIframeDialog {
  constructor(
    public dialogRef: MatDialogRef<InteractiveIframeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { url: string },
    public sanitizer: DomSanitizer
  ) {}

  onClose(): void {
    this.dialogRef.close();
    console.log("AQUI DEBERIA IR EL SALE")
  }
}
