<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img src="../../../../assets/images/BRAND2.png" alt="logo de Billcentrix">
                </a>
            </div>
        </div>
        <div class="setting-data">
            <div class="second-headboard">
                <h1>Afiliación de clientes</h1>
                <p>Afilia tus clientes o envía link para afiliación</p>
            </div>
            <div class="general-data">
                <h1>Datos del cliente</h1>
                <form [formGroup]="clientForm" >
                <div class="grid">
                    <div class="left">
                        <div class="items">
                            <label for="name">Nombre del cliente*</label>
                            <input [formControl]="clientForm.controls['name_customer']" 
                                    type="text" placeholder="Nombre">
                                    <small
                                    *ngIf="clientForm.controls['name_customer'].hasError('required') &&
                                    clientForm.controls['name_customer'].touched"
                                    class="form-error-msg"> Nombre es requerido</small>
                        </div>
                        <div class="items">
                            <label for="name">Cédula del cliente*</label>
                            <input [formControl]="clientForm.controls['identifier_customer']" 
                                    type="text" placeholder="Cédula">
                                    <small
                                    *ngIf="clientForm.controls['identifier_customer'].hasError('required') &&
                                    clientForm.controls['identifier_customer'].touched"
                                    class="form-error-msg"> Cédula es requerido</small>
                        </div>
                        <div class="items">
                            <label for="name">Teléfono del cliente*</label>
                            <input (keypress)="onlyNumberKey($event)"  [formControl]="clientForm.controls['phoneNumber_customer']" 
                                    type="text" placeholder="Teléfono">
                                    <small
                                    *ngIf="clientForm.controls['phoneNumber_customer'].hasError('required') &&
                                    clientForm.controls['phoneNumber_customer'].touched"
                                    class="form-error-msg"> Teléfono es requerido</small>
                        </div>
                        <div class="items">
                            <label for="country">Provincia*</label>
                            <div class="select-wrapper">
                                <select (change)="onProvinceChange()" [formControl]="clientForm.controls['province']">
                                    <option   selected="" value="">Seleccione un opción</option>
                                    <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>
                                </select>
                                <i class="arrow"></i>
                                <small  *ngIf="clientForm.controls['province'].hasError('required') && clientForm.controls['province'].touched"
                                        class="form-error-msg"> Provincia es requerido 
                                </small>
                            </div>
                        </div>
                        <div class="items">
                            <label for="name">Dirección*</label>
                            <input [formControl]="clientForm.controls['address']" 
                                    type="text" placeholder="Dirección">
                                    <small
                                    *ngIf="clientForm.controls['address'].hasError('required') &&
                                    clientForm.controls['address'].touched"
                                    class="form-error-msg"> Dirección es requerido</small>
                        </div>
                    </div>
                    <div class="right">
                        <div class="items">
                            <label for="name">Apellido del cliente*</label>
                            <input [formControl]="clientForm.controls['lastName_customer']" 
                                    type="text" placeholder="Apellido">
                                    <small
                                    *ngIf="clientForm.controls['lastName_customer'].hasError('required') &&
                                    clientForm.controls['lastName_customer'].touched"
                                    class="form-error-msg"> Apellido es requerido</small>
                        </div>

                        <div class="items">
                            <label for="name">Email del cliente*</label>
                            <input [formControl]="clientForm.controls['email_customer']" 
                                    type="text" placeholder="Email">
                                    <small
                                    *ngIf="clientForm.controls['email_customer'].hasError('required') &&
                                    clientForm.controls['email_customer'].touched"
                                    class="form-error-msg"> Email es requerido</small>
                        </div>

                        <div class="items">
                            <label for="country">Plan que se desea suscribir*</label>
                            <div class="select-wrapper">
                                <select [formControl]="clientForm.controls['plan']">
                                    <option   selected="" value="">Seleccione un plan</option>
                                    <option   *ngFor="let plan of plans" [value]="plan.id" >{{ plan.name_plan }}</option>
                                </select>
                                <i class="arrow"></i>
                                <small  *ngIf="clientForm.controls['plan'].hasError('required') && clientForm.controls['plan'].touched"
                                        class="form-error-msg"> Plan es requerido 
                                </small>
                            </div>
                        </div>
                        <div class="items">
                            <label >Corregimiento*</label>
                            <div class="select-wrapper">
                                <select [formControl]="clientForm.controls['corregimiento']" id="corregimiento" name="corregimiento">
                                    <option   selected="" value="">Seleccione un opción</option>
                                    <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>
                                </select>
                                <i class="arrow"></i>
                                <small  *ngIf="clientForm.controls['corregimiento'].hasError('required') && clientForm.controls['corregimiento'].touched"
                                        class="form-error-msg"> Corregimiento es requerido 
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>

            <div class="two-column">
                <div class="general-data plus">
                    <h1>Datos de pago</h1>
                    <form [formGroup]="paymentForm" >
                    <div class="">
                        <div class="left">
                            <div class="items">
                                <label for="name">Nombre del tarjetahambiente*</label>
                                <input [formControl]="paymentForm.controls['nameHolderCreditCard']" 
                                        type="text" placeholder="Nombre">
                                        <small
                                        *ngIf="paymentForm.controls['nameHolderCreditCard'].hasError('required') &&
                                        paymentForm.controls['nameHolderCreditCard'].touched"
                                        class="form-error-msg"> Nombre es requerido</small>
                            </div>
                            <div class="items">
                                <label for="name">Número de Tarjeta*</label>
                                <input (keypress)="onlyNumberKey($event)" [formControl]="paymentForm.controls['numberCreditCard']" 
                                        type="text" placeholder="Número">
                                        <small
                                        *ngIf="paymentForm.controls['numberCreditCard'].hasError('required') &&
                                        paymentForm.controls['numberCreditCard'].touched"
                                        class="form-error-msg"> Número es requerido</small>
                            </div>
                            <div class="grid">
                            <div class="items">
                                <label for="country">Mes de Expiración*</label>
                                <div class="select-wrapper">
                                    <select [formControl]="paymentForm.controls['expMonthCreditCard']">
                                        <option value="" disabled selected>Seleccionar Mes </option>
                                        <option value="01" id="mes-exp-0">Enero</option>
                                        <option value="02" id="mes-exp-1">Febrero</option>
                                        <option value="03" id="mes-exp-2">Marzo</option>
                                        <option value="04" id="mes-exp-3">Abril</option>
                                        <option value="05" id="mes-exp-4">Mayo</option>
                                        <option value="06" id="mes-exp-5">Junio</option>
                                        <option value="07" id="mes-exp-6">Julio</option>
                                        <option value="08" id="mes-exp-7">Agosto</option>
                                        <option value="09" id="mes-exp-8">Septiembre</option>
                                        <option value="10" id="mes-exp-9">Octubre</option>
                                        <option value="11" id="mes-exp-10">Noviembre</option>
                                        <option value="12" id="mes-exp-11">Diciembre</option>
                                    </select>
                                    <i class="arrow"></i>
                                    <small  *ngIf="paymentForm.controls['expMonthCreditCard'].hasError('required') && paymentForm.controls['expMonthCreditCard'].touched"
                                            class="form-error-msg"> Mes es requerido 
                                    </small>
                                </div>
                            </div>
                            <div class="items">
                                <label for="country">Año de Expiración:*</label>
                                <div class="select-wrapper">
                                    <select [formControl]="paymentForm.controls['expYearCreditCard']">
                                        <option value="" disabled selected>Seleccionar año </option>
                                        <option value="24" id="ano-exp-2">2024</option>
                                        <option value="25" id="ano-exp-3">2025</option>
                                        <option value="26" id="ano-exp-4">2026</option>
                                        <option value="27" id="ano-exp-5">2027</option>
                                        <option value="28" id="ano-exp-6">2028</option>
                                        <option value="29" id="ano-exp-7">2029</option>
                                        <option value="30" id="ano-exp-8">2030</option>
                                        <option value="31" id="ano-exp-9">2031</option>
                                        <option value="32" id="ano-exp-10">2032</option>
                                        <option value="33" id="ano-exp-11">2033</option>
                                        <option value="34" id="ano-exp-12">2034</option>
                                        <option value="35" id="ano-exp-13">2035</option>
                                        <option value="36" id="ano-exp-14">2036</option>
                                        <option value="37" id="ano-exp-15">2037</option>
                                        <option value="38" id="ano-exp-16">2038</option>
                                        <option value="39" id="ano-exp-17">2039</option>
                                        <option value="40" id="ano-exp-18">2040</option>
                                    </select>
                                    <i class="arrow"></i>
                                    <small  *ngIf="paymentForm.controls['expYearCreditCard'].hasError('required') && paymentForm.controls['expYearCreditCard'].touched"
                                            class="form-error-msg"> Año es requerido 
                                    </small>
                                </div>
                            </div>
                          </div>
                            <div class="items">
                                <label for="name">CVV*</label>
                                <input (keypress)="onlyNumberKey($event)"  [formControl]="paymentForm.controls['ccv']" 
                                type="text" placeholder="CVV">
                                <small
                                *ngIf="paymentForm.controls['ccv'].hasError('required') &&
                                paymentForm.controls['ccv'].touched"
                                class="form-error-msg"> CVV es requerido</small>
                            </div>
                        </div>
                        
                       
                    </div>
                    </form>
                </div>

                <div class="">
                    <button class="continue-btn" (click)="send(false)" >Afiliar</button> o  <button class="continue-btn" (click)="send(true)" >Enviar email al cliente</button>
                </div>
            </div>
        </div>
        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
            <button class="continue-btn" (click)="send(false)" >Afiliar</button>
        </div>
    </div>
</body>