import { Component, OnInit , ViewChild,ChangeDetectorRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { TableService } from '../services/pager/pager';
import { CustomerFilter } from './customer.filter';
import { WebService } from '../services/web/web.service';
import { SpinnerService } from '../services/spinner/spinner.service';
import { ToastService } from '../services/notification/toast.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  // PARA MANEJO DE PANEL DE BUSQUEDA
  isOpenSearchPanel = false;
  filter = new CustomerFilter(this.tableService.filter);
 
   // PARA MANEJO DE TABLAS
   @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
   @ViewChild(MatSort) sort: MatSort | undefined;
   displayedColumns: string[] = [ 'name_customer','identifier_customer','code_customer', 'phoneNumber_customer','actions'];
   dataSource: any;

   constructor(
    public  tableService: TableService<any>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,private webService : WebService,private spinner : SpinnerService,private toast : ToastService) {
      this.tableService = new TableService;
    }


  ngOnInit(): void {
      this.isOpenSearchPanel = true;
      console.log(JSON.parse(localStorage.getItem('sesion') || '{}'));
      this.list();
  }

    

    list(event?: PageEvent){

      this.spinner.open();

      if(this.tableService.sort == undefined){
        this.tableService.sort = "name_customer";
      }
  
      let httpParams = this.webService.buildRequestParams(this.tableService.sort,"m",
          {pageIndex: event ? event.pageIndex  : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
  
      httpParams = this.filter.getHttpParams(httpParams);
      // 118  JSON.parse(localStorage.getItem('sesion') || '{}').partner
      if(JSON.parse(localStorage.getItem('sesion') || '{}').partner == undefined){
        this.spinner.close();
        return this.toast.showInfo("NO se puede cargar reporte de clientes porque NO posees datos de tu compañía, por favor crea una suscripcion para poder cargar tus datos en nuestro sistema.")
      }

      this.webService.get(this.webService.HOST + "/partner/"+  JSON.parse(localStorage.getItem('sesion') || '{}').partner  + "/customer",httpParams).subscribe(response => {
      
        for(const r of response['result']){
          r.status_customer = r.status_customer  === 1 ? 'Activo' : 'Inactivo';
          r.adress_customer = r.adress_customer === null ? "-" : r.adress_customer; 
          r.phoneNumber_customer = r.phoneNumber_customer === null ? "-" : r.phoneNumber_customer;
        }
        
        this.dataSource = new MatTableDataSource<any>(response['result']);
        this.tableService.pager = response['pager'];
        this.tableService.selection.clear();
        this.spinner.close();
        }, (err) => {
          this.spinner.close();
          this.toast.showError(err);});

    }


    search(){
      this.tableService.filter = new CustomerFilter(this.filter);
      this.router.navigate([], { queryParams: { identifier_customer: this.filter.identifier_customer, code_customer: this.filter.code_customer, name_customer: this.filter.name_customer } });
      this.list();
    }

    openSearchPanel(value: boolean) {
      this.isOpenSearchPanel = value;
      this.filter = new CustomerFilter(this.filter);
    }

    reset() {
      this.filter = new CustomerFilter();
      this.tableService.filter = null;
      this.dataSource = [];
      delete this.filter.name_customer;
      delete this.filter.code_customer;
      delete this.filter.identifier_customer;
      // this.isOpenSearchPanel = false;
      this.search();
    }

    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    update(item: any) { // EDITAR
      this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    }

    plans(item : any){
       this.router.navigate(["./" + item.id + "/plans"], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    }
  
    creditcard(item: any){
      this.router.navigate(['./' + item.id + '/cards'], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    }

    invoices(item: any){
      this.router.navigate(['./' + item.id + '/invoices'], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    }

    goBack(){
      this.router.navigate(["/my-portals"]); 
    }

}
