import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']

})
export class WelcomeComponent {
  showWelcomeComponent: boolean = true;
  showCreateComponent: boolean = true;

  constructor(private router : Router){}

  onLoginClick() {
    this.router.navigate(["/login"])
    
  }
  goHome() {
    this.router.navigate(['/']);
  }
  onSignUp(){
    this.router.navigate(["/create"]); 
  }

 
  
}