import { HttpParams } from '@angular/common/http';

export class ReportFilter {

    id: number | undefined;

    company: number | undefined;
    parameter: string | undefined;
    customer : number | undefined;
    
	constructor(filter?: ReportFilter) {
		if (filter) {
          
            this.id = filter.id;
            this.company = filter.company;
            this.parameter = filter.parameter;
            this.customer = filter.customer;

		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
    
  
        if (this.customer) {
			httpParams = httpParams.set('customer', this.customer.toString());
        }
    
        if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
        }
      
        if (this.company) {
			httpParams = httpParams.set('company', this.company.toString());
        }
        if (this.parameter) {
			httpParams = httpParams.set('parameter', this.parameter);
        }
     
		return httpParams;
	}
}