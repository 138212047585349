import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { WebService}  from '../../services/web/web.service';
import { ToastService } from '../../services/notification/toast.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {
  form: FormGroup;
  public username: string = '';
  public partner : any;

  provincesList: any[]= [] ;
  provincesGeneralList: any[]= [] ;
  corregimientos: any[]= [] ;
  corregimientosSlected : any[]= [] ;

  constructor(private spinner: SpinnerService, private webService: WebService, private toast: ToastService,
    private router : Router) {
    this.form = this.updateFormGroup();
  }

  ngOnInit(): void {
    var sesion = JSON.parse(localStorage.getItem('sesion')!);
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/province").subscribe(response =>{
        this.provincesList = response.result; // CARGAMOS TODAS LAS PROVINCIAS ACTIVAS DEL SISTEMA
        this.provincesGeneralList = response.result;
        this.partner = sesion.partner; // TOMAMOS EL PARTNER EN SESION
        console.log(`stored partner ${this.partner}`);
        this.webService.get(this.webService.HOST + "/partner/" + this.partner).subscribe(partner =>{ // BUSCAMOS LA INFORMACION DEL PARTNER
          // PROCEDEMOS A SETEAR EN PANTALLA LAS PROVINCIAS QUE TIENE ESE PARTNER ASOCIADAS EN LA TABLA PROVINCES PARTNERS
          // LA ISTRUCCION DE ABAJO FILTRARA LA LISTA MAS GRANDE QUE CONTIENE TODAS LAS PROVINCIAS CON LOS IDS DE LAS PROVINCIAS 
          // QUE TIENE EL PARTNER ASOCIADAS EN BS
          this.form.controls["provinces"].setValue(this.provincesList.filter(item1 => partner.result.provinces.some(item2 => item1.id === item2.id)));
          // AQUI VAMOS A RECORRER Y ARMAR UN NUEVO ARREGLO QUE CONTENDRA LOS CORREGIMIENTOS QUE TIENE ESE PARTNER ASOCIADOS
          // ESTO PARA QUE EL PARTNER PUEDA DETERMINAR EN QUE CORREGMIENTOS EN ESPECIFICO OFRECERA SU SERVICIO
          partner.result.partnerCorregimientos.forEach(element => {
            this.corregimientosSlected.push(element.corregimiento)
          });
          // AQUI LO QUE HACEMOS ES DETERMINAR SI DEBEMOS PRESELECCIONAR TODOS LOS CORREGIMIENTOS EN PANTALLA O NO
          let preSelectCorregimientos = this.corregimientosSlected.length == 0 ? true : false;
          this.onProvinceChange(preSelectCorregimientos);
        });
    }, err =>{
      this.spinner.close()
      console.log(err);
    })
    
    
    
  }

  onProvinceChange(selectAll : boolean){

    this.corregimientos = []; // LIMPIAMOS EL ARREGLO EN PANTALLA
    // AQUI LO QUE HAREMOS ES RECORRER TODAS LAS PROVINCIAS QUE TIENE ASIGANDAS EL PARTNER
    // TOMAREMOS TODOS LOS CORREGIMIENTOS QUE TENGAN Y ARMAREMOS EL ARREGLO GENERAL DE CORREGIMIENTOS ASOCIADOS A ESAS PROVINCIAS
    this.form.value.provinces.forEach( p => {
      this.provincesGeneralList[this.provincesGeneralList.findIndex(t => t.id == p.id )].corregimientos.forEach(element => {
        this.corregimientos.push(element)
      });
    });
    this.corregimientos.sort((a,b) =>  (a.name > b.name ? 1 : -1));
    if(selectAll){ // EN ESTE CASO SELECCIONAMOS TODOS LOS CORREGIMIENTOS DE ESTAS PROVINCIAS EN PANTALLA
      this.form.controls["corregimientos"].setValue(this.corregimientos);
      this.spinner.close();
    }else{
      // AQUI VAMOS A FILTRAR EL ARREGLO GRANDE DE CORREGIMIENTOS CON EL ARREGLO DE CORREGIMIENTOS QUE TIENE ASIGNADO EL PARTNER 
      // AUTORIZADOS PARA MOSTRAR SUS SERVICIOS
      this.form.controls["corregimientos"].setValue(this.corregimientos.filter(item1 => this.corregimientosSlected.some(item2 => item1.id === item2.id)))
      this.spinner.close();
    }
  }

  updateFormGroup(): FormGroup {
    return new FormGroup({
      provinces: new FormControl(''),
      corregimientos: new FormControl('')
    });
  }

  // Función personalizada de validación
  passwordsMatchValidator(passwordField: AbstractControl): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === passwordField.value ? null : { 'mismatch': true };
    };
  }

  send(){
    this.spinner.open()
    this.form.value.user = JSON.parse(localStorage.getItem('sesion')!).user_id;
    let corregimientosToSend : any[]= [];
    let provinceToSend : any[]= [];
    this.form.value.corregimientos.forEach(corregmiento => {
      corregimientosToSend.push({id:corregmiento.id})
    });
    this.form.value.provinces.forEach(provinces => {
      provinceToSend.push({id:provinces.id})
    });

    let json = { provinces : provinceToSend, corregimientos : corregimientosToSend, partner :  this.partner}
    console.log(json);

    this.webService.put(json, this.webService.HOST + "/onboarding/partner").subscribe( response =>{
      this.spinner.close();
      this.toast.showSuccess("Informacion actualizada con exito");
      this.goBack();
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
      console.log(err)
    })
  }

  goBack(){
    this.router.navigate(["/my-portals"]); 
  }
}
