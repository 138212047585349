import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '../../../services/notification/toast.service';
import { WebService}  from '../../../services/web/web.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {Location} from "@angular/common";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentPopupComponent } from '../../../account-settings/plans/payment-popup/payment-popup.component';
import {PlanService} from "../../../account-settings/plans/plan.service";

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.scss']
})
export class CreatePlanComponent {

  plans : any = [];
  
  form: FormGroup ;

  @ViewChild('inputFile') myInputVariable: ElementRef | undefined;

  showPaymentButton : Boolean = false;
  frecuencies : any;
  frecuencyName: string = 'Mensuales';
  imageName = null;
	image64: any;
	imageType: any;
  onboardingConfigID: any;
  constructor(private dialog: MatDialog,private planService : PlanService, private location : Location,private activateRoute : ActivatedRoute, private router : Router,private toast : ToastService,private spinner : SpinnerService,private webService : WebService ) {

    this.form = new FormGroup({
      name_plan: new FormControl('', Validators.required),
      feeCost_plan: new FormControl('', Validators.required),
      freezeCostPlan : new FormControl('', Validators.required),
      enrollmentCost_plan: new FormControl(0),
      frecuency : new FormControl('', Validators.required),
      status_plan : new FormControl(1, Validators.required),
      taxRate_plan : new FormControl('', Validators.required),
      service: new FormControl(''),
      description : new FormControl('', Validators.required),
      idPlan_partner : new FormControl("123", Validators.required),
      cobro_by_partner : new FormControl(false, Validators.required),
      verify_invoices_on_partner : new FormControl(false, Validators.required),
      charge_inmediately : new FormControl(false, Validators.required),
      qty_installments_to_collect_plan: new FormControl(''),
      is_visible_plan: new FormControl(true, Validators.required)
    });

  }

  ngOnInit(): void {
    this.spinner.open();

    this.webService.get(this.webService.HOST + "/frecuency").subscribe( response =>{
      this.frecuencies = response.result;
      this.activateRoute.params.subscribe(params =>{

        this.onboardingConfigID = params['id']
        this.getConfigByID();

      }, err =>{
        this.toast.showError(err);
      });
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  // getConfigByID(){
  //   this.spinner.open();
  //   this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{
  //     this.spinner.close();
  //     this.form.controls["service"].setValue({id:config.result.service.id});
  //   }, err =>{
  //     this.spinner.close();
  //     this.toast.showError(err);
  //   });
  // }
  getConfigByID(){
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.onboardingConfigID).subscribe( config =>{
      this.spinner.close();
      this.form.controls["service"].setValue({id:config.result.service.id});
      // console.log('customerHasPlan:', config.result.customerHasPlan);
      // if(config.result.service.current_onboarding_step >= 5){
        if(config.result.customerHasPlan != null ){
          if(config.result.customerHasPlan.status_customerPlan == 1){
            // console.log('Status of customerHasPlan:', config.result.customerHasPlan.status_customerPlan);
            var nextBillDate = new Date(config.result.customerHasPlan.next_bill_date);
            var today = new Date();
            var isValidDate = nextBillDate > today;
            if(!isValidDate){
              this.showPaymentButton = true;
            }
          }else{
            this.showPaymentButton = true;
          }
          // if(config.result.customerHasPlan.status_customerPlan != 1){
          //   this.showPaymentButton = true;
          // }
  
        }else{
          this.showPaymentButton = true;
        }
      // }
      
      this.planService.plans.emit(config.result.plans)
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

 
  send(){
    this.form.value.frecuency = { id : +this.form.value.frecuency }
    this.form.value.image64 = this.image64;
    this.form.value.imageName = this.imageName;
    this.form.value.imageType = this.imageType;
    this.form.value.onboarding_config = { id :  +this.onboardingConfigID }
 
 this.form.value.qty_installments_to_collect_plan = +this.form.value.qty_installments_to_collect_plan;
 this.form.value.is_visible_plan = this.form.value.is_visible_plan === 'true';

    if(!this.form.valid){
      return this.toast.showError("Porfavor, complete el formulario...")
    }

    console.log(this.form.value);

    this.spinner.open();

    this.webService.post({plan:this.form.value, onboarding:true}, this.webService.HOST + "/plan").subscribe(response =>{

      this.spinner.close();
      this.toast.showSuccess("Plan creado correactamente...");
      // @ts-ignore: Object is possibly 'null'.
      this.myInputVariable.nativeElement.value = '';
      this.router.navigate(["/edit/portal/"+this.onboardingConfigID+"/my-plans"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP4
      // this.location.back();
      // this.resetFormVariables();
      // this.ngOnInit();

    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });


  }

  resetFormVariables() {
    // Resetear las variables del formulario
    this.imageName = null;
    this.image64 = null;
    this.imageType = null;
    // Agrega cualquier otra variable que necesites restablecer aquí

    // Resetear los valores del FormGroup a sus valores originales o valores por defecto
    this.form.reset({
      name_plan: '',
      feeCost_plan: '',
      freezeCostPlan: '',
      enrollmentCost_plan: 0,
      frecuency: '',
      status_plan: 1,
      taxRate_plan: '',
      service: '',
      description: '',
      idPlan_partner: '123',
      cobro_by_partner: false,
      verify_invoices_on_partner: false,
      charge_inmediately: false,
      frecuencyName:'Mensuales'
    });
  }

  onLogoChange(event : any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageName = file.name;
        this.imageType = file.type;
        this.image64 = reader.result!.toString();
      };
    }
  }

  goBack(){
    this.location.back();
  }

  goToPortals(){
    this.router.navigate(["/my-portals"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  }
  goToTemplates(){
    this.router.navigate(["/portal"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
  

  }
 

  onChangeFrecuency(index: number) {
    index = index - 1;  // restamos 1 al índice
    this.frecuencyName = this.frecuencies[index].name_frecuency;
  }

  processPayment(){
    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentPopupComponent, {
      width: '100%',
      disableClose: true,
      data: {  configID : this.onboardingConfigID }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(res != undefined &&  res == 1){// SOLO SI ES COBRADO
          this.router.navigateByUrl("/congratulation");
        }
      })
  }

}
