import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../services/web/web.service';
import { ToastService } from '../services/notification/toast.service';
import {SpinnerService} from '../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  clientForm: FormGroup ;
  paymentForm: FormGroup ;
  banks : any;
  countries : any;
  plans : any;
  currencies : any;
  numbers : any;
  exist : Boolean = false;
  isNewService : Boolean = false;
  isPartnerCreated : Boolean = false;
  onboardingConfigID: any;
  isMailContactSended : Boolean = false;
  name_portal: any;
  provinces: any[]= [] ;
  corregimientos: any[]= [] ;

  constructor(private activateRoute : ActivatedRoute,private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService) {
    this.clientForm = new FormGroup({
      name_customer: new FormControl('', Validators.required),
      lastName_customer: new FormControl('', Validators.required),
      identifier_customer : new FormControl('', Validators.required),
      email_customer : new FormControl('',[Validators.required]),
      phoneNumber_customer: new FormControl('', Validators.required),
      config: new FormControl(''),
      code_customer: new FormControl(''),
      status_customer: new FormControl(1),
      service: new FormControl(''),
      plan :  new FormControl('',Validators.required),
      partner :  new FormControl(''),
      onboardingSuscription :  new FormControl(true),
      address: new FormControl('', Validators.required),
      province : new FormControl(''),
      corregimiento : new FormControl('', Validators.required)
    });
    this.paymentForm = new FormGroup({
      nameHolderCreditCard: new FormControl('', Validators.required),
      numberCreditCard: new FormControl('', Validators.required),
      expMonthCreditCard : new FormControl('', Validators.required),
      expYearCreditCard : new FormControl('',[Validators.required]),
      ccv: new FormControl('', Validators.required)
    });
   }

   ngOnInit(): void {
      this.spinner.open();
      this.activateRoute.params.subscribe(params =>{
        this.spinner.close();
        this.clientForm.controls["config"].setValue(params["id"]);
        this.getConfigByID(params["id"]);
        this.spinner.open()
        this.webService.get(this.webService.HOST + "/province").subscribe( provinces =>{
          
          this.provinces = provinces.result;
  
        }, err =>{
          this.spinner.close();
          this.toast.showError(err);
        });

      }, err=>{
        this.spinner.close();
        this.toast.showError(err);
      })
  }

  onProvinceChange(){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == Number(this.clientForm.value.province) )].corregimientos;
    this.corregimientos.sort((a,b) =>  (a.name > b.name ? 1 : -1));

  }

  getConfigByID(id : any){
    this.webService.get(this.webService.HOST + "/onboardingConfig/" +id).subscribe( config =>{
      this.spinner.close();
      this.plans = config.result.plans.filter((p: { status_plan: number | any[]; }) => p.status_plan == 1);
      this.clientForm.controls["partner"].setValue({ id : +config.result.partner.id});
      this.clientForm.controls["service"].setValue(+config.result.service.id);
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

   send(emailSuscription : boolean){
    if(!this.clientForm.valid){
      return this.toast.showInfo("Por favor complete el formulario de Datos del cliente");
    }
    if(!this.paymentForm.valid){
      return this.toast.showInfo("Por favor complete el formulario de Datos del pago");
    }

    this.spinner.open();
    this.clientForm.value.code_customer = this.clientForm.value.email_customer;

    this.webService.post(this.clientForm.value, this.webService.HOST + "/onboarding/user").subscribe(response =>{
      let address : any = {};
      address.name = this.clientForm.value.name;
      address.lastname = this.clientForm.value.lastname;
      address.address = this.clientForm.value.address;
      address.province = this.clientForm.value.province;
      address.corregimiento = this.clientForm.value.corregimiento;
      if(emailSuscription){ // AGREGAMOS ESTA TARJETA FALSA, SOLO PARA PODER GENERAR LA SUSCRIPCION MIENTRAS EL CLIENTE LOGRA AGREGAR SU TARJETA
        this.paymentForm.value.numberCreditCard = "4111111111111111";
        this.paymentForm.value.nameHolderCreditCard = "Test";
        this.paymentForm.value.expMonthCreditCard = "01";
        this.paymentForm.value.ccv = "123";
        this.paymentForm.value.expYearCreditCard = "40";
      }
      var subscription = { suscribeByEmail : emailSuscription, onboardingClientSuscription : true,address:address,param: 8, creditcard: this.paymentForm.value, customer:  +response.result.id, plan : +this.clientForm.value.plan};
      this.webService.post(subscription, this.webService.HOST + "/suscription").subscribe(response =>{
        this.spinner.close();
        this.toast.showSuccess("Suscripcion procesada correctamente");
        this.goBack();
      }, err=>{
        this.spinner.close();
        this.toast.showError(err);
      });
    }, err =>{
      this.toast.showError(err.message);
      console.log(err);
      this.spinner.close();
    });
    

   }

   goBack(){
    this.router.navigate(["/my-portals"]); 
  }

   omit_special_char(event: { charCode: any; }){   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
   }

   onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


}
