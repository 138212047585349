import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../services/notification/toast.service';
import { WebService } from '../../../services/web/web.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Location } from "@angular/common";

@Component({
  selector: 'app-update-plan',
  templateUrl: './update-plan.component.html',
  styleUrls: ['./update-plan.component.scss']
})
export class UpdatePlanComponent implements OnInit {

  plans: any = [];
  form: FormGroup;
  frecuencies: any;
  imageName = null;
  image64: any;
  imageTemp: any;
  imageType: any;
  onboardingConfigID: any;
  planID: any;
  frecuencyName: string = 'Mensuales';

  constructor(
    private location: Location,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private spinner: SpinnerService,
    private webService: WebService
  ) {
    this.form = new FormGroup({
      id: new FormControl(''),
      name_plan: new FormControl('', Validators.required),
      feeCost_plan: new FormControl('', Validators.required),
      freezeCostPlan: new FormControl('', Validators.required),
      enrollmentCost_plan: new FormControl(0),
      frecuency: new FormControl('', Validators.required),
      status_plan: new FormControl('', Validators.required),
      taxRate_plan: new FormControl('', Validators.required),
      service: new FormControl(''),
      description: new FormControl('', Validators.required),
      idPlan_partner: new FormControl("123", Validators.required),
      cobro_by_partner: new FormControl(false, Validators.required),
      verify_invoices_on_partner: new FormControl(false, Validators.required),
      charge_inmediately: new FormControl(false, Validators.required),
      qty_installments_to_collect_plan: new FormControl(''),
      is_visible_plan: new FormControl(false, Validators.required)
    });
  }

  ngOnInit(): void {
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/frecuency").subscribe(response => {
      this.frecuencies = response.result;
      this.activateRoute.params.subscribe(params => {
        this.onboardingConfigID = params['id'];
        this.planID = +params['plan'];
        this.webService.get(this.webService.HOST + "/plan/" + this.planID).subscribe(response => {
          const plan = response.result;
          if(response.has_suscriptions){
            this.form.controls['qty_installments_to_collect_plan'].disable();
          }
          this.form.patchValue({
            id: plan.id,
            name_plan: plan.name_plan,
            description: plan.description,
            frecuency: plan.frecuency.id,
            status_plan: plan.status_plan,
            feeCost_plan: plan.feeCost_plan,
            freezeCostPlan: plan.freezeCostPlan,
            enrollmentCost_plan: plan.enrollmentCost_plan,
            taxRate_plan: plan.taxRate_plan,
            service: { id: plan.service.id },
            cobro_by_partner: plan.cobro_by_partner,
            verify_invoices_on_partner: plan.verify_invoices_on_partner,
            charge_inmediately: plan.charge_inmediately,
            qty_installments_to_collect_plan: plan.qty_installments_to_collect_plan === null ? '' : plan.qty_installments_to_collect_plan,
            is_visible_plan: plan.is_visible_plan
          });
          this.imageTemp = plan.url_image;
          this.frecuencyName = plan.frecuency.name_frecuency;
          this.spinner.close();
        }, err => {
          this.spinner.close();
          this.toast.showError(err);
        });
      }, err => {
        this.toast.showError(err);
        this.spinner.close();
      });
    }, err => {
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  send() {
    if (!this.form.valid) {
      return this.toast.showError("Por favor, complete el formulario...");
    }

    // Ajustar la frecuencia y configurarla en el formato esperado por el backend
    const frq = this.form.value.frecuency;
    this.form.value.frecuency = { id: frq };

    // Agregar datos de imagen y configuración
    this.form.value.image64 = this.image64;
    this.form.value.imageName = this.imageName;
    this.form.value.imageType = this.imageType;
    this.form.value.onboarding_config = { id: +this.onboardingConfigID };

    console.log("Datos del formulario antes de enviar:", this.form.value);

    this.spinner.open();
    this.webService.put({ plan: this.form.value, onboarding: true }, this.webService.HOST + "/partner/" + this.planID + "/plan/" + this.planID).subscribe(
      response => {
        this.spinner.close();
        this.toast.showSuccess("Plan actualizado correctamente...");
        this.location.back();
      },
      err => {
        this.spinner.close();
        this.toast.showError(err);
      }
    );
  }

  onLogoChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageName = file.name;
        this.imageType = file.type;
        this.image64 = reader.result!.toString();
      };
    }
  }

  goBack() {
    this.router.navigate(["../.."], { relativeTo: this.activateRoute, queryParamsHandling: "preserve" });
  }

  onChangeFrecuency(index: number) {
    index = index - 1;  // restamos 1 al índice
    this.frecuencyName = this.frecuencies[index].name_frecuency;
  }

  handleCreatePlanClick() {
    this.router.navigate(["/create"], { relativeTo: this.activateRoute, queryParamsHandling: "preserve" });
  }
}
