
          <div class="preview">
            <div class="box-detail">
                  <div class="block-one">
                    <h3>Suscripción</h3>
                    <p>Modificado {{ portal.service.updatedAt }}</p>
                  </div>
                  <div class="block-two">
                  <div class="left-block">
                    <!-- <button  *ngIf="portal.customerHasPlan != null && portal.customerHasPlan.status_customerPlan == 1"     class="bot"  (click)="goAddClient(portal)"> -->
                    <button   class="bot"  (click)="goAddClient(portal)">
                      <img src="/assets/icons/plus-circle.svg" alt="">
                      Afiliar cliente
                    </button>
                 
                    <!-- <button  *ngIf="portal.customerHasPlan == null || portal.customerHasPlan.status_customerPlan != 1"    class="bot disable" (click)="goAddClient(portal)">
                      <span>Debe tener una suscripción activa</span>
                      <img src="/assets/icons/plus-circle.svg" alt="">
                      Afiliar cliente
                    </button> -->
                   
                 
                  <button class="bot"  (click)="goEdit(portal)">
                    <img src="/assets/icons/pencil.svg" alt="">
                    Editar Suscripción
                  </button>
                  </div>
                      <div class="status-plan">
                        <button style="color: white;" class="inactivo status" *ngIf="portal.customerHasPlan != null && portal.customerHasPlan.status_customerPlan == 1"  (click)="goDeactivatePortal(portal)">Desactivar Suscripción</button>
                        <!-- <button style="color: white;" class="inactivo status" *ngIf="(portal.customerHasPlan != null && portal.customerHasPlan.status_customerPlan != 1) || (!portal.isActive  &&  portal.customerHasPlan == null && portal.service.current_onboarding_step >= 4) || (!showDeactivate && !isValidDate)  ">Reactivar suscripción</button> -->
                        <button style="color: white;" class="draf status"*ngIf="portal.customerHasPlan == null || portal.customerHasPlan.status_customerPlan != 1" (click)="processPayment(portal)">Activar suscripción</button>
                        <!-- <button style="color: white;" class="procesando status" *ngIf="!portal.isActive && portal.customerHasPlan != null && portal.customerHasPlan.status_customerPlan == 1 && isValidDate" [matMenuTriggerFor]="menu">Procesando</button> -->
                    </div>
                  </div>
              

                <mat-menu class="option-portal" #menu="matMenu">
                </mat-menu>

            </div>
            
            <div class="content-portal">
              <h2 >{{ portal.service.name_service }}</h2>
              
            </div>
            <div *ngIf="portal.onboardingPortalContent == null" class="nuevo-portal">
              <div class="leftdato"><img src="/assets/icons/box.svg" alt="">
              <h3>portal</h3>
              <p>Si deseas que tus clientes puedan afiliarse online a través de un portal web, activa esta opción</p></div>
            <button (click)="goToTemplates(portal,false)">Crear portal para afiliaciones</button>
            </div>
            
              <div *ngIf="portal.onboardingPortalContent != null" class="editar-portal">
                <div class="portal-sub">
                  <img src="/assets/icons/box-white.svg" alt="">
                  <h3>Portal de afiliaciones</h3>
                </div>
                <div class="custom-portal">
                  <button (click)="goToTemplates(portal,true)" > <img src="/assets/icons/pencil-white.svg" alt="">Editar template</button>
                  <button (click)="goToFeatures(portal)" > <img src="/assets/icons/pencil-white.svg" alt="">Editar colores e imágenes</button>
                  <button *ngIf="isPortalActive" (click)="goToPortal(portal.url_client)" > <img src="/assets/icons/eye-white.svg" alt="">Vista previa</button>
                  <button *ngIf="!isPortalActive"  (click)="goToPortal(portal.url_template)" > <img src="/assets/icons/eye-white.svg" alt="">Vista previa</button>
                </div>
                </div>
              </div>
          
