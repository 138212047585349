import { Component, OnInit , ViewChild,ChangeDetectorRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { TableService } from '../services/pager/pager';
import { CustomerFilter } from './customer.filter';
import { WebService } from '../services/web/web.service';
import { SpinnerService } from '../services/spinner/spinner.service';
import { ToastService } from '../services/notification/toast.service';
import {SecurityService} from "../services/security.service";
import {HttpParams} from "@angular/common/http";


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  // PARA MANEJO DE PANEL DE BUSQUEDA
  isOpenSearchPanel = false;
  filter = new CustomerFilter(this.tableService.filter);

  services: any[] = [];
  plansByService: any[] = [];
  selectedServices: any[] = [];
  selectedPlans: any[] = [];
 
   // PARA MANEJO DE TABLAS
   @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
   @ViewChild(MatSort) sort: MatSort | undefined;
   displayedColumns: string[] = [ 'name_customer','identifier_customer','code_customer', 'phoneNumber_customer','actions'];
   dataSource: any;

   constructor(
    public  tableService: TableService<any>,
    private securityService : SecurityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,private webService : WebService,private spinner : SpinnerService,private toast : ToastService) {
      this.tableService = new TableService;
    }


  ngOnInit(): void {
      this.isOpenSearchPanel = true;
      // console.log(JSON.parse(localStorage.getItem('sesion') || '{}'));
      this.loadServices();
      this.list();
  }

  loadServices(): void {
    this.spinner.open();
    const partnerId = this.securityService.getTokenPartner();
    if (!partnerId) {
      this.spinner.close();
      return this.toast.showInfo("No se puede cargar servicios porque no se encontró un partner.");
    }

    this.webService.get(`${this.webService.SECURITY_HOST}/partner/${partnerId}/services`)
        .subscribe(
            (response: any) => {
              this.services = response.result;
              // Sort services alphabetically by name_service
              this.services.sort((a: any, b: any) => {
                if (a.name_service < b.name_service) {
                  return -1;
                }
                if (a.name_service > b.name_service) {
                  return 1;
                }
                return 0;
              });
              this.spinner.close();
            },
            (error) => {
              this.spinner.close();
              this.toast.showError(error);
            }
        );
  }

  onPlanSelectionChange(selectedPlans: any[]): void {
    if (selectedPlans.includes('selectAll')) {
      if (this.selectedPlans.length - 1  === this.plansByService.length) {
        // Si todos los servicios ya están seleccionados, deselecciona todos
        this.selectedPlans = [];
      } else {
        // Si no todos los servicios están seleccionados, selecciona todos
        this.selectedPlans = this.plansByService.map(plan => plan.id);
      }
    } else {
      this.selectedPlans = selectedPlans;
    }

    this.selectedPlans.sort((a: any, b: any) => {
      if (a.name_plan < b.name_plan) {
        return -1;
      }
      if (a.name_plan > b.name_plan) {
        return 1;
      }
      return 0;
    });

  }


  onServiceSelectionChange(selectedServices: any[]): void {
    if (selectedServices.includes('selectAll')) {
      if (this.selectedServices.length - 1  === this.services.length) {
        // Si todos los servicios ya están seleccionados, deselecciona todos
        this.selectedServices = [];
      } else {
        // Si no todos los servicios están seleccionados, selecciona todos
        this.selectedServices = this.services.map(service => service.id);
      }
    } else {
      this.selectedServices = selectedServices;
    }

    this.selectedServices.sort((a: any, b: any) => {
      if (a.name_service < b.name_service) {
        return -1;
      }
      if (a.name_service > b.name_service) {
        return 1;
      }
      return 0;
    });

    this.loadPlansForSelectedServices();
  }

  loadPlansForSelectedServices(): void {
    if (this.selectedServices.length === 0) {
      this.plansByService = [];
      return;
    }

    this.spinner.open();
    // const params = new HttpParams().set('list', this.selectedServices.join(','));
    this.webService.get(`${this.webService.SECURITY_HOST}/partner/services/plan?list=` + this.selectedServices.join(','))
        .subscribe(
            (response: any) => {
              this.plansByService = response.result;
              this.spinner.close();
              // Sort services alphabetically by name_service
              this.plansByService.sort((a: any, b: any) => {
                if (a.name_plan < b.name_plan) {
                  return -1;
                }
                if (a.name_plan > b.name_plan) {
                  return 1;
                }
                return 0;
              });
            },
            (error) => {
              this.spinner.close();
              this.toast.showError(error);
            }
        );
  }

  // Añadir esta función para convertir HttpParams a string con formato nombre=value
  private httpParamsToString(params: HttpParams): string {
    let result: string[] = [];
    params.keys().forEach(key => {
      result.push(`${key}=${params.get(key)}`);
    });
    return result.join('&');
  }

    

    list(event?: PageEvent){

      this.spinner.open();

      if(this.tableService.sort == undefined){
        this.tableService.sort = "name_customer";
      }

      this.filter.services = this.selectedServices.join(',');
      this.filter.plans = this.selectedPlans.join(',');
  
      let httpParams = this.webService.buildRequestParams(this.tableService.sort,"m",
          {pageIndex: event ? event.pageIndex  : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})
  
      httpParams = this.filter.getHttpParams(httpParams);
      // 118  JSON.parse(localStorage.getItem('sesion') || '{}').partner
      if(this.securityService.getTokenPartner() == undefined){
        this.spinner.close();
        return this.toast.showInfo("NO se puede cargar reporte de clientes porque NO posees datos de tu compañía, por favor crea una suscripcion para poder cargar tus datos en nuestro sistema.")
      }

      // Y luego en la función list(), después de definir httpParams:
      const paramsString = this.httpParamsToString(httpParams);
      console.log(this.webService.SECURITY_HOST + "/partner/"+  this.securityService.getTokenPartner()  + "/customer?" + paramsString);
      this.webService.get(this.webService.SECURITY_HOST + "/partner/"+  this.securityService.getTokenPartner()  + "/customer?" + paramsString).subscribe(response => {

        console.log(response)
        for(const r of response['result']){
          r.status_customer = r.status_customer  === 1 ? 'Activo' : 'Inactivo';
          r.adress_customer = r.adress_customer === null ? "-" : r.adress_customer;
          r.phoneNumber_customer = r.phoneNumber_customer === null ? "-" : r.phoneNumber_customer;
        }

        let customers = response['result'];
        customers.sort((a: any, b: any) => {
          if (a.name_customer < b.name_customer) {
            return -1;
          }
          if (a.name_customer > b.name_customer) {
            return 1;
          }
          return 0;
        });
        this.dataSource = new MatTableDataSource<any>(customers);
        this.tableService.pager = response['pager'];
        this.tableService.selection.clear();
        this.spinner.close();
        }, (err) => {
          this.spinner.close();
          this.toast.showError(err);});

    }


    search(){
      this.tableService.filter = new CustomerFilter(this.filter);
      this.router.navigate([], { queryParams: { identifier_customer: this.filter.identifier_customer, code_customer: this.filter.code_customer, name_customer: this.filter.name_customer } });
      this.list();
    }

    openSearchPanel(value: boolean) {
      this.isOpenSearchPanel = value;
      this.filter = new CustomerFilter(this.filter);
    }

    reset() {
      this.filter = new CustomerFilter();
      this.tableService.filter = null;
      this.dataSource = [];
      delete this.filter.name_customer;
      delete this.filter.code_customer;
      delete this.filter.identifier_customer;
      this.selectedServices = [];
      this.selectedPlans = [];
      // this.isOpenSearchPanel = false;
      this.search();
    }

    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    update(item: any) { // EDITAR
      this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    }

    plans(item : any){
       this.router.navigate(["./" + item.id + "/plans"], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    }
  
    creditcard(item: any){
      this.router.navigate(['./' + item.id + '/cards'], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    }

    invoices(item: any){
      this.router.navigate(['./' + item.id + '/invoices'], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    }

    goBack(){
      this.router.navigate(["/my-portals"]); 
    }

}
