<body>
    <div class="grid-overlay">
        <div class="header">
            <div class="logo">
                <a  href="/my-portals">
                    <img src="../../assets/images/BRAND2.png"  alt="logo de Billcentrix">
                </a>
            </div>
            <div class="buttons">
                <!-- <button class="create-report-btn"><h1>+</h1></button> -->
                <img src="../../assets/icons/reportes_aqua.png" alt="icono reportes">
                <app-menu></app-menu>
            </div>
        </div>
        
        <div class="reports-data">
            <!-- <div class="second-headboard">
                <h1>Reportes</h1>
                <button>Gestión de clientes</button>
                <button>Facturación</button>
                <button>Reporte diario</button>
            </div> -->
            <div> <h1>Reporte de clientes</h1></div>
            <mat-accordion>
                <mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
                    <mat-expansion-panel-header>
        
                        <button class="btn-icon btn"  title="Buscar" type="button">
                            <i class="material-icons">search</i>
                        </button>
                    </mat-expansion-panel-header>
            
                    <form>
                        <fieldset class="fieldset">
                               
                                <div fxLayout="row wrap" class="fild_search">
                                    <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                        <div class="pb-16">
                                           
                                            <mat-form-field class="full-width">
                                                <mat-label>Nombre</mat-label>
                                                <input
                                                matInput
                                                name="name_customer"
                                                placeholder="Search by customer name"
                                                value=""
                                                [(ngModel)]="filter.name_customer">
                                            </mat-form-field>
                                        </div>
                                    </div>
        
                                    <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                        <div class="pb-16">
                                            
                                            <mat-form-field class="full-width">
                                                <mat-label>DNI</mat-label>
                                                <input
                                                matInput
                                                name="identifier_customer"
                                                placeholder="Search by DNI"
                                                value=""
                                                [(ngModel)]="filter.identifier_customer">
                                            </mat-form-field>
                                        </div>
                                    </div>
        
                                    <div fxFlex="100" fxFlex.gt-xs="33" class="pr-16">
                                        <div class="pb-16">
                                            
                                            <mat-form-field class="full-width">
                                                <mat-label>Código</mat-label>
                                                <input
                                                matInput
                                                name="code_customer"
                                                placeholder="Search by Code"
                                                value=""
                                                [(ngModel)]="filter.code_customer">
                                            </mat-form-field>
                                        </div>
                                    </div>
                    
                                </div>
        
                        </fieldset>
                        <div class="button_report_align"fxLayout="row" fxLayoutAlign="end center" >
                            
                                <button
                                mat-raised-button
                                type="button"
                                color="warn"
                                class="ml-16 mr-16 button_report light"
                                (click)="reset()"
                                >
                                <mat-icon>refresh</mat-icon> 
                                </button>
        
                                <button
                                mat-raised-button
                                type="button"
                                color="primary"
                                class="ml-16 button_report blue"
                                (click)="search()">
                                <mat-icon>search</mat-icon>
                                </button>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>

            <div style="padding-top: 2em;">
                <mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();" >
        
                
                    <ng-container matColumnDef="name_customer">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="name_customer"> {{row.name_customer}} </mat-cell>
                    </ng-container>
        
                    <ng-container matColumnDef="identifier_customer">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> DNI </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="identifier_customer"> {{row.identifier_customer}} </mat-cell>
                      </ng-container>
        
                        
                    <ng-container matColumnDef="code_customer">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Código </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="code_customer"> {{row.code_customer}} </mat-cell>
                      </ng-container>
        
                   
        
                      <ng-container matColumnDef="phoneNumber_customer">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="phoneNumber_customer"> {{row.phoneNumber_customer}} </mat-cell>
                      </ng-container>
                
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="estatus"> 
                            <button mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                                <mat-icon class="text-muted">more_vert</mat-icon>
                              </button>
                            <mat-menu style="width: 200px;" #msgMenu="matMenu">
                                <button mat-menu-item (click)="update(row)" class="menu_report"><img src="../assets/icons/update.svg"> Actualizar </button>
                                <button mat-menu-item (click)="plans(row)" class="menu_report"><img src="../assets/icons/plan.svg" alt=""> Planes </button>
                                <button mat-menu-item (click)="creditcard(row)" class="menu_report"><img src="../assets/icons/credit-card.svg" alt=""> Tarjetas </button>
                                <button mat-menu-item (click)="invoices(row)" class="menu_report"><img src="../assets/icons/invoice.svg" alt=""> Facturas  </button>
                            </mat-menu>
                        </mat-cell>
                      </ng-container>
        
                    
                
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="read(row)" >
                    </mat-row>
                </mat-table>
                <mat-paginator showFirstLastButtons
                        [length]="tableService.pager.totalEntities"
                        [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                        [pageSizeOptions]="[20, 50, 100]"
                        (page)="list($event)">
                </mat-paginator>  
            </div>
            
        </div>
        <div class="options-buttons">
            <button class="back-btn" (click)="goBack()" >Atrás</button>
        </div>
    </div>
</body>