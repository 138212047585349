import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WebService}  from '../../services/web/web.service';
import { ToastService } from '../../services/notification/toast.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PortalService} from "../portal/my-portals/portal.service";
import {Location} from "@angular/common";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {PaymentPopupComponent} from "../plans/payment-popup/payment-popup.component";



import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-update-portal',
  templateUrl: './update-portal.component.html',
  styleUrls: ['./update-portal.component.scss']
})
export class UpdatePortalComponent implements OnInit {
  partnerForm: FormGroup ;
  showHelpText: boolean = false;
  banks : any;
  countries : any;
  cities : any;
  currencies : any;
  numbers : any;
  exist : Boolean = false;
  configID : any;
  isNewService : Boolean = false;
  showPaymentButton : Boolean = true;
  name_portal: any;
  step: any;
  country : Number = 0;
  currency : Number = 0;
  isDisabled : Boolean = false;

  constructor(private http : HttpClient,private dialog: MatDialog,private location: Location ,private portalService : PortalService,private activateRoute : ActivatedRoute,private router : Router,private spinner : SpinnerService,private webService : WebService,private toast : ToastService) {
    this.partnerForm = new FormGroup({
      id: new FormControl(''),
      url: new FormControl(''),
      name_partner: new FormControl('', Validators.required),
      phone_partner: new FormControl('', Validators.required),
      adress_partner : new FormControl('', Validators.required),
      country : new FormControl(''),
      city: new FormControl('', Validators.required),
      status_partner: new FormControl(1, Validators.required),
      bank: new FormControl('', Validators.required),
      whatsappNumber: new FormControl('', Validators.required),
      email_partner :  new FormControl('', [Validators.required,Validators.email]),
      feeRate_partner : new FormControl(1.00),
      name_portal : new FormControl('', Validators.required),
      name_service : new FormControl(''),
      currency_id: new FormControl('', Validators.required),
      service_id: new FormControl(''),
      acummulate_debt : new FormControl(false,Validators.required),
      create_email_notification : new FormControl(false,Validators.required)
    });
  }
  ngOnInit(): void {


  // this.http.get('https://test-pedro-local.billcentric.com', { observe: 'response' })
  // .pipe(first())
  // .subscribe(resp => {
  //   if (resp.status === 200 ) {
  //     this.toast.showSuccess("ESTA ARRIBA LA PAGINA")
  //     console.log(true);
  //   } else {
  //     this.toast.showError("ESTA ABAJO LA PAGINA")
  //     console.log(false);
  //   }
  // }, err => console.log(err));

    this.spinner.open();
    this.activateRoute.params.subscribe( params =>{
      
      this.configID = params['id'];
      var sesion = JSON.parse(localStorage.getItem('sesion')!);
      // BUSCAMOS TODOS LOS BANCOS
      let partner = -1;
      if(sesion.partner != null || sesion.partner != undefined){
        partner = sesion.partner
      }
    this.webService.get(this.webService.HOST + "/onboardingBanks/"+this.webService.ONBOARDING_ID + "/partner/" + partner).subscribe( banks =>{
     

        this.banks = banks.result;
       

        // BUSCAMOS LOS PAISES REGISTRADOS
        this.webService.get(this.webService.HOST + "/country").subscribe( countries =>{

          this.countries = countries.result;

          this.webService.get(this.webService.HOST + "/whatsapp").subscribe( numbers =>{

            this.numbers = numbers.result;

            this.webService.get(this.webService.HOST + "/onboardingConfig/" + this.configID).subscribe( config =>{
             
              if(config.result.url_client !== null){
                this.partnerForm.controls["url"].setValue(config.result.url_client);
              }
              // console.log(config.result.partner);
              this.spinner.close();
              // LLENAMOS EL FORMULARIO CON LA INFORMACION CORRESPONDIENTE
              console.log(config.result.partner)
              this.partnerForm.controls["id"].setValue(config.result.partner.id);
              this.partnerForm.controls["name_partner"].setValue(config.result.partner.name_partner);
              this.partnerForm.controls["phone_partner"].setValue(config.result.partner.phone_partner);
              this.partnerForm.controls["adress_partner"].setValue(config.result.partner.adress_partner);
              this.partnerForm.controls["country"].setValue(config.result.partner.city.country.id);
              this.cityByCountry(config.result.partner.city.country.id);
              this.partnerForm.controls["city"].setValue(config.result.partner.city.id);
              this.partnerForm.controls["status_partner"].setValue(config.result.partner.status_partner);
              this.partnerForm.controls["currency_id"].setValue(config.result.service.currency.id);
              this.partnerForm.controls["acummulate_debt"].setValue(config.result.service.acummulate_debt);
              this.partnerForm.controls["create_email_notification"].setValue(config.result.partner.create_email_notification);
              this.partnerForm.controls["service_id"].setValue(config.result.service.id);
              // this.partnerForm.controls['currency_id'].disable();
              this.name_portal = config.result.service.name_service;
              this.partnerForm.controls["name_portal"].setValue(this.name_portal);

              if(config.result.partner.bank !== null){
                this.partnerForm.controls["bank"].setValue(config.result.partner.bank.id);
              }

              if(config.result.partner.whatsappNumber !== null){
                this.partnerForm.controls["whatsappNumber"].setValue(config.result.partner.whatsappNumber.id);
              }
              
              this.step = config.result.service.current_onboarding_step;
              this.partnerForm.controls["email_partner"].setValue(config.result.partner.email_partner);
              this.partnerForm.controls["feeRate_partner"].setValue(config.result.partner.feeRate_partner);           
              if(config.result.customerHasPlan != null &&  config.result.customerHasPlan.status_customerPlan  === 1){
                this.showPaymentButton = false
              }

              if(config.result.service.current_onboarding_step > 6){
                this.country = this.partnerForm.value.country;
                this.currency = this.partnerForm.value.currency_id;
                this.partnerForm.controls['country'].disable();
                this.partnerForm.controls['currency_id'].disable();
                this.isDisabled = true;
              }

            }, err =>{
              this.spinner.close();
              this.toast.showError(err);
            });

          }, err =>{
            this.spinner.close();
            this.toast.showError(err);
          });

        }, err =>{
          this.spinner.close();
          this.toast.showError(err);
        });

      }, err=>{
        this.spinner.close();
        this.toast.showError(err);
      });

    }, err =>{
      this.toast.showError(err);
    });
  }

  cityByCountry(country : any){

    this.spinner.open();

    // BUSCAMOS LA CIUDADES DEPENDIENDO DEL PAIS SELECCIONADO
    this.webService.get(this.webService.HOST + "/city/country/" + country).subscribe( response =>{

      this.cities = response.result;
      if (this.cities.length > 0) {
        this.currencies = this.cities[0].country.currencies;
        }
      this.spinner.close();

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);

    })

  }

  update(){
   
    this.spinner.open();

    if(!this.partnerForm.valid){
      this.spinner.close();
      this.toast.showError("Datos incompletos o invalidos, por favor verifique...")
      return;
    }
    const data =  this.partnerForm.value;

    data.bank = { id : +data.bank };
    data.city = { id : +data.city };
    data.whatsappNumber = { id : +data.whatsappNumber };
    data.config = +this.configID;

    if(this.isDisabled){
      data.country = this.country;
      data.currency_id = this.currency;
    }

    if(data.acummulate_debt == null){
      data.acummulate_debt == false;
    }

    if(data.create_email_notification == null){
      data.acummulate_debt == false;
    }

    this.webService.put({partner:data},this.webService.HOST + "/partner/" + data.id).subscribe(response =>{
      
      // HEMOS CREAMOS EL PARTNER POR LO CUAL ACTUALIZAMOS EL OBJETO EN SESION PARA MANTENER EL ID DEL PARTNER
      this.toast.showSuccess("Cambios guardados correctamente")
      this.spinner.close();
      this.goBack();

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.toast.showError(err);
    });

  }

  processPayment(){
    if (this.step > 0 && this.step < 5) {
      this.toast.showWarning("Por favor complete todos los pasos.");
      return;
  }
  // if (this.step === 6 || this.step === 7 || this.step === -1) {
  //     this.toast.showInfo("El Portal web ya está lanzado en producción.");
  //     return;
  // }
    

    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentPopupComponent, {
      width: '80%',
      disableClose: true,
      data: {
        configID: this.configID
      }
    })
    dialogRef.afterClosed()
      .subscribe(res => {


      })
  }

  sendContactMail(type : number){

    var json : any = {};
    json.type = type;

    this.spinner.open();
    // mandamos el correo
    json.partner = this.partnerForm.value.id;
    this.webService.post(json, this.webService.HOST + "/onboarding/step1/sendMail").subscribe( response =>{
        console.log(response);
        this.spinner.close();
        this.toast.showSuccess("En breve uno de nuestros ejecutivos se estará comunicando con usted para procesar su requerimiento...")
    }, err =>{
        this.spinner.close();
        this.toast.showError("No se pudo enviar el correo, por favor contacta con el administrador");
    });


  }

  goBack(){
    this.router.navigate(["/my-portals"]); 
  }

  goToPlans(){
    
    this.router.navigate(['.', 'my-plans'], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

  goToTemplates(){
    
    this.router.navigate(['.', 'portal'], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }

  goToFeatures(){
    
    this.router.navigate(['.', 'features'], {relativeTo : this.activateRoute ,queryParamsHandling : "preserve"}); // ENVIAMOS AL CLIENTE PARA EL STEP2
  }
  
 
  omit_special_char(event: { charCode: any; }){   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
